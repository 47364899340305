.container[data-v-61526e66] {
  width: 100%;
  height: 100%;
}

/*-------TOP CUSTOM-------*/
.custom-top[data-v-61526e66] {
  position: relative;

  padding: 15px;
  padding-left: 30px;
  padding-right: 45px;

  display: flex;
  flex-direction: row;
  align-items: center;

  background-color: #3c8cc9;
}
.custom-id-tag[data-v-61526e66] {
  position: absolute;
  top: 20px;
  right: 20px;
  color: #ffffff;
  font-size: 24px;
}
.author[data-v-61526e66] {
  color: #ffffff;
  font-size: 32px;
  font-weight: 500;
}
.custom-icon[data-v-61526e66] {
  fill: #ffffff;
  min-width: 30px;
  margin-right: 10px;
}

/*-------TOP STANDARD-------*/
.standard-top[data-v-61526e66] {
  padding: 25px;
  padding-left: 50px;
  padding-right: 50px;

  background-color: #3c8cc9;
}
.card-top-bottom[data-v-61526e66] {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: space-between;
}
.type[data-v-61526e66] {
  color: #ffffff;
  font-size: 24px;
}
.id-tag[data-v-61526e66] {
  color: #ffffff;
  font-size: 24px;
}
.title[data-v-61526e66] {
  color: #ffffff;
  font-size: 42px;
  font-weight: 600;

  margin-bottom: 10px;
}

/*-------TIE-------*/
.card-tie[data-v-61526e66] {
  padding-left: 50px;
  padding-right: 50px;
  padding-top: 20px;
  padding-bottom: 10px;

  font-size: 24px;
  background-color: #f7f7f7;
}
.tie-set[data-v-61526e66] {
  display: flex;
  flex-direction: row;
  align-items: center;

  margin-bottom: 5px;
}
.tie-icon[data-v-61526e66] {
  fill: #b1b1b1;
  min-width: 30px;
  margin-right: 20px;
}
.signatories-list[data-v-61526e66] {
  color: #b1b1b1;
}

/*-------BODY-------*/
.card-body[data-v-61526e66] {
  flex-grow: 1;

  padding-left: 50px;
  padding-right: 50px;
  padding-top: 30px;
  padding-bottom: 30px;

  border-radius: 0 0 10px 10px;
  background-color: #ffffff;
  overflow-y: auto;
}
.card-body[data-v-61526e66]:hover::-webkit-scrollbar-track {
  background: rgba(241, 241, 241, 1);
  opacity: 100%;
  -webkit-transition: 200ms;
  transition: 200ms;
}
.card-body[data-v-61526e66]:hover::-webkit-scrollbar-thumb {
  background: rgba(220, 220, 220, 1);
  opacity: 100%;
  -webkit-transition: 200ms;
  transition: 200ms;
}
.block-heading[data-v-61526e66] {
  font-size: 24px;
  font-weight: 500;
  word-wrap: break-word;

  margin-bottom: 3px;
}
.block-text[data-v-61526e66] {
  color: #222222;
  font-size: 24px;
  word-wrap: break-word;
  margin-bottom: 20px;
  white-space: pre-line;
}

.fade[data-v-420d6df8] {
  opacity: 0%;
}
.form-container[data-v-420d6df8] {
  display: flex;
  flex-direction: column;
}

/*----BRICKED FORM----*/
.bricked-container[data-v-420d6df8] {
  width: 100%;
  height: 100%;

  margin-bottom: 30px;
  padding-left: 25px;
  padding-right: 25px;
  background-color: #f3f3f3;
  border-radius: 10px;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.bricked-header[data-v-420d6df8] {
  font-size: 28px;
  font-weight: 700;
  color: #707070;
  margin-bottom: 10px;
  text-align: center;
}
.bricked-subheader[data-v-420d6df8] {
  font-size: 18px;
  font-weight: 500;
  color: #707070;
  text-align: center;
}

/*-----SUBMIT BUTTON-----*/
.submit-container[data-v-420d6df8] {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  position: relative;

  padding-top: 10px;
  padding-bottom: 50px;
}
.btt-submit[data-v-420d6df8] {
  width: 150px;

  padding: 8px;
  padding-left: 15px;
  padding-right: 15px;

  display: flex;
  justify-content: center;
  align-items: center;

  border-radius: 5px;
  background-color: #bae4fa;
  color: #00a3ff;
  transition: 200ms;

  font-size: 16px;
  font-weight: 500;

  cursor: pointer;
  -webkit-user-select: none; /* Safari */ /* Firefox */ /* IE10+/Edge */
  user-select: none; /* Standard */
}
.btt-submit[data-v-420d6df8]:hover {
  background-color: #7eb6df;
  color: #ffffff;
  transition: 200ms;
}
.warning[data-v-420d6df8] {
  position: absolute;
  right: 150px;

  margin-right: 20px;

  font-size: 14px;
  font-weight: 500;
  color: #ff7070;
  transition: 200ms;
}

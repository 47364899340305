/*----------PAGE UI----------*/
.editor-container[data-v-990ed547] {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  height: 100%;

  display: flex;
  justify-content: center;
}
.main-UI[data-v-990ed547] {
  width: 1170px;
  height: 100%;

  display: flex;
  flex-direction: column;
}
.fade[data-v-990ed547] {
  opacity: 0%;
  transition: 200ms;
}
.noselect[data-v-990ed547] {
  -webkit-user-select: none; /* Safari */ /* Firefox */ /* IE10+/Edge */
  user-select: none; /* Standard */
}

/*----------FORM UI----------*/
.hat-UI[data-v-990ed547] {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;

  padding-right: 430px;
  padding-bottom: 30px;
  margin-top: 30px;
}
.preview-hat[data-v-990ed547] {
  display: flex;
  flex-direction: column;
  justify-content: center;
  -webkit-transform: translate(50px);
          transform: translate(50px);
  white-space: nowrap;
}
.preview-hat-heading[data-v-990ed547] {
  max-width: 400px;
  font-size: 32px;
  font-weight: 600;
  color: #bcbcbc;
}
.preview-hat-subheading[data-v-990ed547] {
  font-size: 18px;
  font-weight: 500;
  color: #bcbcbc;
}
.preview-editor-pair[data-v-990ed547] {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  overflow: visible;
}

/*----- Share Button -----*/
.link-container[data-v-990ed547] {
  position: absolute;
  top: 30px;
  right: 30px;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.btt-link[data-v-990ed547] {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  height: 35px;

  display: flex;
  align-items: center;
  justify-content: center;

  padding: 5px;
  padding-left: 15px;
  padding-right: 15px;
  margin-left: 10px;

  border-radius: 10px;
  background-color: #bae4fa;

  transition: 200ms;
  cursor: pointer;
  font-size: 16px;
  font-weight: 600;
  color: #378dcc;
}
.btt-link[data-v-990ed547]:hover {
  background-color: #7eb6df;
  color: white;
  transition: 200ms;
}
.btt-link:hover > .link-icon[data-v-990ed547] {
  fill: white;
  transition: 200ms;
}
.link-icon[data-v-990ed547] {
  width: 20px;
  height: 20px;
  fill: #378dcc;
  transition: 200ms;
}
.confirmation[data-v-990ed547] {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;

  margin-top: 10px;
  padding: 5px;
  padding-left: 10px;
  padding-right: 10px;
  border-radius: 5px;
  background-color: #ffffff;

  font-size: 10px;
  font-weight: 500;
  transition: 200ms;
}

.inbox-container[data-v-0f792567] {
  width: 100%;
  height: 85%;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

/*--------------TOP UI--------------*/
.UI-top[data-v-0f792567] {
  width: 75%;
  max-width: 1600px;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  position: relative;

  margin-left: auto;
  margin-right: auto;
  margin-bottom: 50px;

  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.filter-container[data-v-0f792567] {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.card-count[data-v-0f792567] {
  font-size: 16px;
  font-weight: 500;
  color: #bcbcbc;
}
.left-spacer[data-v-0f792567] {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 225px;
}

/*---------BOTTOM UI--------*/
.UI-bottom[data-v-0f792567] {
  height: 100%;
  width: 100%;

  display: flex;
  flex-direction: row;

  overflow-x: auto;
  overflow-y: hidden;
}
.UI-bottom[data-v-0f792567]::-webkit-scrollbar {
  display: none;
}
.spacer[data-v-0f792567] {
  min-width: 12.5%;
}
.spacer2[data-v-0f792567] {
  min-width: 11%;
}
.no-cards-box[data-v-0f792567] {
  width: 100%;
  height: 100%;

  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;

  font-size: 20px;
  font-weight: 600;
  color: #bcbcbc;

  background-color: #f3f3f3;
  border-radius: 10px;
}

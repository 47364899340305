.footer[data-v-54ce981c] {
  width: 100%;
  min-height: 50px;
  background-color: #424242;

  display: flex;
  align-items: center;
  justify-content: center;
}
.footer-contents[data-v-54ce981c] {
  width: 60%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.footer-text[data-v-54ce981c] {
  display: flex;
  flex-direction: row;
  align-items: center;

  color: #f3f3f3;
  font-weight: 200;
  font-size: 14px;
}
.name[data-v-54ce981c] {
  display: flex;
  flex-direction: row;
  align-items: center;

  margin-left: 5px;
  margin-right: 5px;

  cursor: pointer;
  color: #f3f3f3;
}
@media (max-width: 641px) {
.footer-text[data-v-54ce981c] {
    font-size: 12px;
}
}

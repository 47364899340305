.container[data-v-4e571d43] {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  overflow: hidden;
}
.gradient[data-v-4e571d43] {
  width: 120vw;
  height: 850px;
  position: absolute;
  top: -300px;
  z-index: 0;

  -webkit-transform: rotate(5deg);

          transform: rotate(5deg);
}
.messagebox[data-v-4e571d43] {
  width: 500px;
  z-index: 2;

  margin: 30px;
  padding: 25px;
  padding-top: 20px;
  padding-bottom: 20px;
  background-color: #ffffff;

  border-radius: 10px;
  box-shadow: -10px 10px 30px rgba(0, 0, 0, 0.05);

  display: grid;
  grid-gap: 20px;
  gap: 20px;
}
.heading[data-v-4e571d43] {
  color: #4a687f;
}
.pairs[data-v-4e571d43] {
  display: grid;
  grid-gap: 15px;
  gap: 15px;
}
.btt-full[data-v-4e571d43] {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  font-size: 14px;
  font-weight: 600;

  padding: 7px;
  padding-left: 15px;
  padding-right: 15px;

  background-color: #dfefff;
  color: #00a3ff;
  border-radius: 5px;
  cursor: pointer;
  text-decoration: none;
}
.btt-full[data-v-4e571d43]:hover {
  background-color: #59c2ff;
  color: #ffffff;
  transition: 200ms;
}

/*----- IconTextPair -----*/
.pair[data-v-4e571d43] {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.icon[data-v-4e571d43] {
  margin-right: 15px;
}
.text[data-v-4e571d43] {
  font-weight: 500;
  color: #707070;
}
.email[data-v-4e571d43] {
  color: #707070;
}

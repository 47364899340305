.App-container[data-v-c68e1569] {
  width: 100%;
  height: 100%;
  background-color: #f7f7f7;

  position: absolute;
  top: 0px;
  left: 0px;

  display: flex;
  flex-direction: row;
}
.UI-container[data-v-c68e1569] {
  width: calc(100% - 60px);
  height: 100%;
  display: flex;
  flex-direction: column;
}
.page-container[data-v-c68e1569] {
  width: 100%;
  display: flex;
  flex-grow: 1;
  align-items: center;
  justify-content: center;
  overflow-y: auto;

  position: relative;
}

/*--------GENERAL FORMATTING-------*/
.fade[data-v-c88d652c] {
  opacity: 0%;
  transition: 200ms;
}
.centering-container[data-v-c88d652c] {
  width: 100%;
  min-height: 90vh;
  display: flex;
  align-items: center;
  justify-content: center;
}
.forgot-container[data-v-c88d652c] {
  width: 60%;
  display: flex;
  flex-direction: column;
  margin-top: 50px;
  margin-bottom: 70px;
}
.form-header[data-v-c88d652c] {
  font-size: 32px;
  font-weight: 700;
}
.form-subheader[data-v-c88d652c] {
  font-weight: 600;
  color: #707070;
}
.input-label[data-v-c88d652c] {
  margin-top: 20px;
  font-size: 14px;
  font-weight: 600;
  color: #707070;
}
.input-field[data-v-c88d652c] {
  width: 300px;
  height: 30px;

  margin-top: 5px;
  padding-left: 10px;
  padding-right: 10px;

  font-size: 14px;

  border-color: rgba(0, 0, 0, 0);
  border-radius: 5px;
  background-color: #ffffff;
}
.input-field[data-v-c88d652c]::-webkit-input-placeholder {
  font-size: 16px;
  color: #c0c0c0;
}
.input-field[data-v-c88d652c]::placeholder {
  font-size: 16px;
  color: #c0c0c0;
}
.input-field[data-v-c88d652c]:focus {
  outline: none !important;
  border-color: rgba(0, 0, 0, 0);
  background-color: #ffffff;
}
.input-field[data-v-c88d652c]:hover {
  background-color: #f7f7f7;
}
.btt-container[data-v-c88d652c] {
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: flex-start;

  margin-top: 70px;
}
.warning[data-v-c88d652c] {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  position: absolute;
  left: 170px;

  font-size: 14px;
  font-weight: 500;
  color: #ff7070;
  transition: 200ms;
}
.btt-send[data-v-c88d652c] {
  width: 150px;
  display: flex;
  align-items: center;
  justify-content: center;

  font-size: 14px;
  font-weight: 700;

  padding: 7px;
  padding-left: 15px;
  padding-right: 15px;

  background-color: #4b6980;
  color: #ffffff;
  border-radius: 5px;
  cursor: pointer;
}
.btt-send[data-v-c88d652c]:hover {
  background-color: #6c92af;
  color: #ffffff;
  transition: 200ms;
}
.icon[data-v-c88d652c] {
  fill: #4b6980;
  margin-bottom: 10px;
}

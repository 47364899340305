.margin-block[data-v-fb1e5d8a] {
  transition: 500ms;
}
.history-container[data-v-fb1e5d8a] {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  height: 100%;

  position: fixed;
  z-index: 99;

  display: flex;
  transition: 500ms;
}
.content[data-v-fb1e5d8a] {
  height: 100%;
  flex-direction: column;
  background-color: #eeeeee;
  display: flex;

  padding-left: 25px;
  padding-right: 25px;
}
.toggle[data-v-fb1e5d8a] {
  position: absolute;

  min-width: 35px;
  min-height: 50px;

  display: flex;
  align-items: center;
  justify-content: center;

  cursor: pointer;
  transition: 500ms;
}
.open[data-v-fb1e5d8a] {
  left: 0px;

  background-color: #f7f7f7;
  border-radius: 0 12rem 12rem 0;
  padding-right: 5px;
}
.closed[data-v-fb1e5d8a] {
  left: -35px;

  background-color: #eeeeee;
  border-radius: 12rem 0 0 12rem;
  padding-left: 5px;
}
.noselect[data-v-fb1e5d8a] {
  -webkit-user-select: none; /* Safari */ /* Firefox */ /* IE10+/Edge */
  user-select: none; /* Standard */
}

/*----- Heading -----*/
.sidebar-top[data-v-fb1e5d8a] {
  margin-top: 20px;
  margin-bottom: 10px;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.btt-page[data-v-fb1e5d8a] {
  width: 140px;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  padding: 5px;

  background-color: #eeeeee;
  border-radius: 15px;
  color: #707070;
  cursor: pointer;
}
.selected[data-v-fb1e5d8a] {
  background-color: #f7f7f7;
  color: #000000;
  font-weight: 600;
}
.btt-text[data-v-fb1e5d8a] {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  margin-left: 8px;
  font-size: 14px;
}
.btt-icon[data-v-fb1e5d8a] {
  fill: #707070;
}
.bold[data-v-fb1e5d8a] {
  fill: #000000;
}
.page-description[data-v-fb1e5d8a] {
  font-size: 14px;
  font-weight: 500;
  color: #aaaaaa;

  margin-top: 10px;
  margin-bottom: 15px;
}

/*----- History -----*/
.history-cards-container[data-v-fb1e5d8a] {
  display: grid;
  grid-row-gap: 10px;
  row-gap: 10px;
  overflow-y: auto;
}
.history-cards-container[data-v-fb1e5d8a]::-webkit-scrollbar {
  display: none;
}
.history-no-submissions-block[data-v-fb1e5d8a] {
  width: 350px;
  flex-grow: 1;
  margin-bottom: 30px;

  display: flex;
  align-items: center;
  justify-content: center;

  border-radius: 10px;
  background-color: #ececec;

  font-size: 24px;
  font-weight: 700;
  color: #bcbcbc;
}

/*----- Draft -----*/
.subheading[data-v-fb1e5d8a] {
  font-size: 14px;
  font-weight: 600;
  color: #707070;
  margin-top: 5px;
}

/*----- Media Query -----*/
@media (max-width: 641px) {
.form-top[data-v-fb1e5d8a] {
    padding-left: 10px;
    padding-right: 10px;
    flex-direction: column;
    margin-bottom: 30px;
}
}

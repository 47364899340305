.container[data-v-cfebb23d] {
  width: 100%;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  padding: 30px;
  padding-top: 5px;
  padding-bottom: 5px;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.message[data-v-cfebb23d] {
  font-weight: 400;
}
.action-text[data-v-cfebb23d] {
  font-weight: 500;
  text-decoration: underline;
  cursor: pointer;
}

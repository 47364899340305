.home-container[data-v-df60ace2] {
  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: column;
  align-items: center;
}

/*---------TOP SECTION---------*/
.home-top[data-v-df60ace2] {
  width: 100vw;
  height: 700px;
  margin-top: 50px;
  margin-bottom: -30px;
}
.home-top-container[data-v-df60ace2] {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  -webkit-transform: translate(-150px, 1300px);
          transform: translate(-150px, 1300px);
  position: relative;
  top: -1970px;
  left: 0;
  z-index: 1;
}
.top-gradient[data-v-df60ace2] {
  width: 120%;
  height: 100%;
  -webkit-transform: rotate(-5deg) translate(100px, 1300px);
          transform: rotate(-5deg) translate(100px, 1300px);

  position: relative;
  top: -1700px;
  left: -300px;
}
.top-left[data-v-df60ace2] {
  display: flex;
  flex-direction: column;
  position: relative;

  padding-right: 250px;
}
.mockup-inbox[data-v-df60ace2] {
  width: 600px;
  box-shadow: -10px 10px 30px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  overflow: hidden;
}
.mockup-form[data-v-df60ace2] {
  width: 600px;
  box-shadow: -10px 10px 30px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  overflow: hidden;

  position: absolute;
  top: 120px;
  left: 150px;
}
.top-right[data-v-df60ace2] {
  width: 300px;
  display: flex;
  flex-direction: column;
  padding-top: 30px;
}
.adword[data-v-df60ace2] {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  background-color: #bae4fa;
  color: #378dcc;
  padding-left: 5px;
  padding-right: 5px;
}
.top-heading[data-v-df60ace2] {
  font-size: 72px;
  font-weight: 800;
  margin-bottom: 20px;
}
.top-subheading[data-v-df60ace2] {
  font-size: 28px;
}
.btt-try-free[data-v-df60ace2] {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  font-size: 18px;
  font-weight: 600;

  padding: 8px;
  padding-left: 25px;
  padding-right: 25px;
  margin-top: 30px;

  background-color: #bae4fa;
  text-decoration: none;
  color: #00a3ff;
  border-radius: 10px;
  cursor: pointer;
}
.btt-try-free[data-v-df60ace2]:hover {
  background-color: #4bb4ff;
  color: #ffffff;
  transition: 200ms;
}

/*---------TRUSTED BY---------*/
.trusted-container[data-v-df60ace2] {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 100px;

  width: 70%;
  max-width: 600px;
  z-index: 100;
}
.trusted-logos[data-v-df60ace2] {
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-around;
}
.trusted-logo[data-v-df60ace2] {
  height: auto;
  width: 75px;
}

/*---------FEATURES SECTION---------*/
.home-features[data-v-df60ace2] {
  width: 100%;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  background: #f6f9fc;

  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: hidden;

  padding-top: 130px;
  padding-bottom: 50px;
}
.home-feature-group-imgText[data-v-df60ace2] {
  width: 1350px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  margin-bottom: 80px;
  -webkit-transform: translate(-150px);
          transform: translate(-150px);
}
.home-feature-group-textImg[data-v-df60ace2] {
  width: 1350px;
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  justify-content: space-between;

  margin-bottom: 80px;
  -webkit-transform: translate(150px);
          transform: translate(150px);
}
.features-image[data-v-df60ace2] {
  width: 750px;
  border-radius: 5px;
  box-shadow: 0px 0px 50px rgba(0, 0, 0, 0.1);
  overflow: hidden;
}
.features-icon-heading-group[data-v-df60ace2] {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
}
.features-icon[data-v-df60ace2] {
  min-width: 50px;
  fill: #47667d;
  margin-right: 25px;
  margin-bottom: 20px;
  text-shadow: -10px 10px 30px rgba(0, 0, 0, 0.15);
}
.feature-text-group[data-v-df60ace2] {
  width: 500px;
  display: flex;
  flex-direction: column;
}
.feature-text-heading[data-v-df60ace2] {
  color: #47667d;
  font-size: 38px;
  font-weight: 800;
  text-shadow: -10px 10px 30px rgba(0, 0, 0, 0.15);
}
.feature-text-subheading[data-v-df60ace2] {
  color: #555555;
  font-size: 22px;
  font-weight: 500;
  text-shadow: -10px 10px 30px rgba(0, 0, 0, 0.15);
}

/*---------MODERNIZE SECTION---------*/
.home-modernize[data-v-df60ace2] {
  width: 100vw;
  height: 600px;
  background: linear-gradient(-45deg, #78aed7, #7aa5c6);

  position: relative;
  overflow: hidden;

  display: flex;
  align-items: center;
  justify-content: center;
}
.modernize-image[data-v-df60ace2] {
  width: 300px;
  border-radius: 10px;
  box-shadow: 0px 0px 40px rgba(0, 0, 0, 0.15);
  overflow: hidden;
}
.modernize-header[data-v-df60ace2] {
  font-size: 36px;
  font-weight: 800;
  color: white;
  margin-bottom: 20px;
  text-shadow: -10px 10px 30px rgba(0, 0, 0, 0.1);
}
.btt-see-options[data-v-df60ace2] {
  width: 200px;
  display: flex;
  align-items: center;
  justify-content: center;

  font-size: 18px;
  font-weight: 700;

  padding: 7px;
  padding-left: 15px;
  padding-right: 15px;

  background-color: rgba(256, 256, 256, 0.3);
  text-decoration: none;
  color: white;
  border-radius: 10px;
  cursor: pointer;
}
.btt-see-options[data-v-df60ace2]:hover {
  background-color: rgba(256, 256, 256, 0.5);
  color: #ffffff;
  transition: 200ms;
}
@media (max-width: 641px) {
.home-top[data-v-df60ace2] {
    margin-top: 0px;
    margin-bottom: 0px;
}
.home-top-container[data-v-df60ace2] {
    flex-direction: column;
    -webkit-transform: none;
            transform: none;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    -webkit-transform: translate(0px, 1300px);
            transform: translate(0px, 1300px);
}
.top-gradient[data-v-df60ace2] {
    -webkit-transform: rotate(-5deg) translate(130px, 1450px);
            transform: rotate(-5deg) translate(130px, 1450px);
}
.top-left[data-v-df60ace2] {
    margin-left: 50px;
    margin-bottom: 50px;
}
.top-right[data-v-df60ace2] {
    width: 100%;
    padding-top: 0px;
    padding-left: 50px;
    padding-right: 50px;
}
.top-heading[data-v-df60ace2] {
    font-size: 44px;
}
.top-subheading[data-v-df60ace2] {
    font-size: 22px;
}
.mockup-inbox[data-v-df60ace2] {
    width: 350px;
}
.mockup-form[data-v-df60ace2] {
    width: 350px;
    position: absolute;
    top: 80px;
    left: 150px;
}
.trusted-logo[data-v-df60ace2] {
    height: auto;
    width: 50px;
    z-index: 100;
}
.home-features[data-v-df60ace2] {
    padding-top: 100px;
}
.home-feature-group-imgText[data-v-df60ace2] {
    flex-direction: column-reverse;
    -webkit-transform: none;
            transform: none;
    width: 100%;
    padding-left: 40px;
    padding-right: 40px;
    margin-bottom: 120px;
}
.home-feature-group-textImg[data-v-df60ace2] {
    flex-direction: column-reverse;
    -webkit-transform: none;
            transform: none;
    width: 100%;
    padding-left: 40px;
    padding-right: 40px;
    margin-bottom: 120px;
}
.feature-text-group[data-v-df60ace2] {
    width: 100%;
    margin-top: 40px;
}
.features-icon-heading-group[data-v-df60ace2] {
    align-items: flex-start;
}
.features-icon[data-v-df60ace2] {
    height: 30px;
}
.feature-text-heading[data-v-df60ace2] {
    font-size: 28px;
}
.feature-text-subheading[data-v-df60ace2] {
    font-size: 16px;
    margin-bottom: 30px;
}
.features-image[data-v-df60ace2] {
    width: 110%;
}
.home-modernize[data-v-df60ace2] {
    height: 700px;
}
.modernize-header[data-v-df60ace2] {
    font-size: 32px;
    text-align: center;
    padding-left: 50px;
    padding-right: 50px;
}
.modernize-image[data-v-df60ace2] {
    width: 250px;
}
.btt-see-options[data-v-df60ace2] {
    display: none;
}
.btt-try-free[data-v-df60ace2]:hover {
    opacity: 1;
}
}

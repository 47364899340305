.cards-container[data-v-67361bbc] {
  width: 650px;

  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.noselect[data-v-67361bbc] {
  -webkit-user-select: none; /* Safari */ /* Firefox */ /* IE10+/Edge */
  user-select: none; /* Standard */
}

/*CARD*/
.card[data-v-67361bbc] {
  width: 300px;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;

  display: flex;
  flex-direction: column;

  border-radius: 10px;
  overflow: hidden;
  box-shadow: -10px 10px 20px rgba(0, 0, 0, 0.1);
}

/*Tie*/
.card-tie[data-v-67361bbc] {
  width: 100%;
  padding: 15px;
  padding-left: 25px;
  padding-right: 25px;

  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  background-color: #deeefe;
}
.icon[data-v-67361bbc] {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  margin-bottom: 10px;
  -webkit-filter: drop-shadow(0px 0px 10px rgba(0, 0, 0, 0.2));
          filter: drop-shadow(0px 0px 10px rgba(0, 0, 0, 0.2));
}
.card-heading[data-v-67361bbc] {
  font-size: 24px;
  font-weight: 700;
  color: #707070;
}
.card-subheading[data-v-67361bbc] {
  font-size: 14px;
  font-weight: 500;
  color: #707070;
}
.price-tag[data-v-67361bbc] {
  font-size: 32px;
  font-weight: 700;
}
.price-subtag[data-v-67361bbc] {
  font-weight: 500;
  color: #707070;
}

/*Body*/
.card-body[data-v-67361bbc] {
  flex-grow: 1;
  padding: 20px;
  padding-left: 25px;
  padding-right: 25px;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: white;
}
.btt-demo[data-v-67361bbc] {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  font-size: 14px;
  font-weight: 600;

  padding: 7px;
  padding-left: 15px;
  padding-right: 15px;

  color: #707070;
  border-style: solid;
  border-width: 2px;
  border-color: #eaeaea;
  border-radius: 5px;
  cursor: pointer;
  text-decoration: none;
}
.btt-demo[data-v-67361bbc]:hover {
  background-color: #eaeaea;
  color: #707070;
  transition: 200ms;
}
.btt-full[data-v-67361bbc] {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  font-size: 14px;
  font-weight: 600;

  padding: 7px;
  padding-left: 15px;
  padding-right: 15px;

  background-color: #dfefff;
  color: #00a3ff;
  border-radius: 5px;
  cursor: pointer;
  text-decoration: none;
}
.btt-full[data-v-67361bbc]:hover {
  background-color: #59c2ff;
  color: #ffffff;
  transition: 200ms;
}
.btt-bricked[data-v-67361bbc] {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  font-size: 14px;
  font-weight: 600;

  padding: 7px;
  padding-left: 15px;
  padding-right: 15px;

  background-color: #f3f3f3;
  color: #bcbcbc;
  border-radius: 5px;
  cursor: pointer;
  text-decoration: none;
}

/*----- App Page -----*/
.app-container[data-v-67361bbc] {
  margin-top: 40px;
  margin-bottom: 20px;
}

/*----- Feature List -----*/
.list-container[data-v-67361bbc] {
  height: 170px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.list-item[data-v-67361bbc] {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
}
.list-check[data-v-67361bbc] {
  min-width: 20px;
  min-height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.list-text[data-v-67361bbc] {
  margin-left: 5px;
  font-weight: 300;
}

.modal-top[data-v-8c29f75c] {
  width: 100%;
}
.modal-body[data-v-8c29f75c] {
  width: 90%;
  flex-grow: 1;

  margin: auto;
  margin-top: 5px;
  margin-bottom: 5px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow-y: hidden;
}
.modal-bottom[data-v-8c29f75c] {
  width: 100%;

  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}
.modal-background[data-v-8c29f75c] {
  width: 100%;
  height: 100%;

  position: fixed;
  top: 0;
  left: 0;
  z-index: 10;

  display: flex;
  justify-content: center;
  align-items: center;

  background-color: rgba(0, 0, 0, 0.3);
}
.disappear[data-v-8c29f75c] {
  display: none !important;
}

/* ----- BUTTONS ------ */
.btt-add-countries[data-v-8c29f75c] {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  height: 40px;

  display: flex;
  justify-content: center;
  align-items: center;

  padding-left: 15px;
  padding-right: 15px;
  margin: 10px;
  background-color: #bae4fa;
  color: #378dcc;
  font-weight: 500;
  border-radius: 5px;

  transition: 200ms;
  cursor: pointer;
  -webkit-user-select: none; /* Safari */ /* Firefox */ /* IE10+/Edge */
  user-select: none; /* Standard */
}
.btt-add-countries[data-v-8c29f75c]:hover {
  background-color: #7eb6df;
  color: #ffffff;
  transition: 200ms;
}
.btt-cancel[data-v-8c29f75c] {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  height: 40px;

  display: flex;
  justify-content: center;
  align-items: center;

  padding-left: 15px;
  padding-right: 15px;
  margin: 10px;
  margin-left: 0px;
  background-color: #f7f7f7;
  font-weight: 500;
  border-radius: 5px;

  transition: 200ms;
  cursor: pointer;
  -webkit-user-select: none; /* Safari */ /* Firefox */ /* IE10+/Edge */
  user-select: none; /* Standard */
}
.btt-cancel[data-v-8c29f75c]:hover {
  background-color: #cdcdcd;
  color: #ffffff;
  transition: 200ms;
}

/*-------TEXT ITEMS--------*/
.modal-header[data-v-8c29f75c] {
  margin-top: 20px;
  margin-left: 25px;
  margin-right: 25px;
  font-size: 20px;
  font-weight: 700;
}
.modal-subheader[data-v-8c29f75c] {
  margin-top: 5px;
  margin-bottom: 15px;
  margin-left: 25px;
  margin-right: 25px;
  font-size: 16px;
  font-weight: 300;
  color: #707070;
}

/*-----------MODAL CONFIRMATION----------*/
.modal-container-confirmation[data-v-8c29f75c] {
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  width: 500px;

  display: flex;
  flex-direction: column;
  justify-content: space-between;

  border-radius: 10px;
  background-color: #ffffff;
}
.btt-confirm[data-v-8c29f75c] {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  height: 40px;

  display: flex;
  justify-content: center;
  align-items: center;

  padding-left: 15px;
  padding-right: 15px;
  margin: 10px;
  background-color: #f3b9c9;
  color: #ff435d;
  font-weight: 500;
  border-radius: 5px;

  transition: 200ms;
  cursor: pointer;
  -webkit-user-select: none; /* Safari */ /* Firefox */ /* IE10+/Edge */
  user-select: none; /* Standard */
}
.btt-confirm[data-v-8c29f75c]:hover {
  background-color: #ff7979;
  color: #ffffff;
  transition: 200ms;
}

/*-----------MODAL UN COUNTRIES----------*/
.modal-container-un[data-v-8c29f75c] {
  height: 80%;
  max-height: 800px;
  width: 500px;

  display: flex;
  flex-direction: column;
  justify-content: space-between;

  border-radius: 10px;
  background-color: #ffffff;
}
.countries-list-container[data-v-8c29f75c] {
  width: 100%;
  height: 100%;
  border-radius: 10px;
  overflow-y: auto;
}
.countries-list-container[data-v-8c29f75c]::-webkit-scrollbar-track {
  background: rgba(241, 241, 241, 1);
  opacity: 100%;
  -webkit-transition: 200ms;
  transition: 200ms;
}
.countries-list-container[data-v-8c29f75c]::-webkit-scrollbar-thumb {
  background: rgba(220, 220, 220, 1);
  opacity: 100%;
  -webkit-transition: 200ms;
  transition: 200ms;
}
.modal-delbar-container[data-v-8c29f75c] {
  width: 100%;

  display: flex;
  align-items: center;
  padding-top: 10px;
  padding-bottom: 10px;

  font-weight: 500;
  background-color: #f7f7f7;
  cursor: pointer;
  transition: 200ms;
}
.modal-country-name[data-v-8c29f75c] {
  margin-left: 25px;
  -webkit-user-select: none; /* Safari */ /* Firefox */ /* IE10+/Edge */
  user-select: none; /* Standard */
}
.selected[data-v-8c29f75c] {
  background-color: #bae4fa;
  transition: 200ms;
}

/*-----------MODAL CUSTOM COUNTRY----------*/
.modal-container-custom[data-v-8c29f75c] {
  height: 250px;
  max-height: 800px;
  width: 500px;

  display: flex;
  flex-direction: column;
  justify-content: space-between;

  border-radius: 10px;
  background-color: #ffffff;
}
.modal-input-container[data-v-8c29f75c] {
  width: 100%;
}
.modal-custom-input[data-v-8c29f75c] {
  width: 100%;
  height: 40px;

  padding-left: 10px;
  padding-right: 10px;

  font-size: 16px;

  border-color: rgba(0, 0, 0, 0);
  border-radius: 10px;
  background-color: #f7f7f7;
}
.modal-custom-input[data-v-8c29f75c]:focus {
  outline: none !important;
  border-color: rgba(0, 0, 0, 0);
  background-color: #f7f7f7;
}
.modal-custom-input[data-v-8c29f75c]:hover {
  background-color: #eeeeee;
}
.btt-custom-add-countries[data-v-8c29f75c] {
  width: 130px;
  height: 40px;

  display: flex;
  justify-content: center;
  align-items: center;

  margin: 10px;
  background-color: #bae4fa;
  color: #378dcc;
  font-weight: 500;
  border-radius: 5px;

  transition: 200ms;
  cursor: pointer;
  -webkit-user-select: none; /* Safari */ /* Firefox */ /* IE10+/Edge */
  user-select: none; /* Standard */
}
.btt-custom-add-countries[data-v-8c29f75c]:hover {
  background-color: #7eb6df;
  color: #ffffff;
  transition: 200ms;
}

/*-----------MODAL SPREADSHEET IMPORT----------*/
.modal-container-spreadsheet[data-v-8c29f75c] {
  height: 80%;
  max-height: 800px;
  width: 500px;

  display: flex;
  flex-direction: column;
  justify-content: space-between;

  border-radius: 10px;
  background-color: #ffffff;
}
.pastebin[data-v-8c29f75c] {
  width: 100%;
  min-height: 100%;

  margin: auto;
  margin-top: 10px;
  margin-bottom: 15px;
  padding: 10px;
  padding-top: 5px;

  font-size: 16px;
  font-family: "Inter", sans-serif;

  border-color: rgba(0, 0, 0, 0);
  border-radius: 10px;
  background-color: #f7f7f7;

  resize: vertical;
  overflow-y: auto;
}
.pastebin[data-v-8c29f75c]::-webkit-input-placeholder {
  font-size: 16px;
  color: #c0c0c0;
}
.pastebin[data-v-8c29f75c]::placeholder {
  font-size: 16px;
  color: #c0c0c0;
}
.pastebin[data-v-8c29f75c]:focus {
  outline: none !important;
  border-color: rgba(0, 0, 0, 0);
  background-color: #f7f7f7;
}
.pastebin[data-v-8c29f75c]:hover {
  background-color: #eeeeee;
}
.pastebin[data-v-8c29f75c]:hover::-webkit-scrollbar-track {
  background: rgba(241, 241, 241, 1);
  opacity: 100%;
  -webkit-transition: 200ms;
  transition: 200ms;
}
.pastebin[data-v-8c29f75c]:hover::-webkit-scrollbar-thumb {
  background: rgba(220, 220, 220, 1);
  opacity: 100%;
  -webkit-transition: 200ms;
  transition: 200ms;
}
.btt-import[data-v-8c29f75c] {
  width: 80px;
  height: 40px;

  display: flex;
  justify-content: center;
  align-items: center;

  margin: 10px;
  background-color: #bae4fa;
  color: #378dcc;
  font-weight: 500;
  border-radius: 5px;

  transition: 200ms;
  cursor: pointer;
  -webkit-user-select: none; /* Safari */ /* Firefox */ /* IE10+/Edge */
  user-select: none; /* Standard */
}
.btt-import[data-v-8c29f75c]:hover {
  background-color: #7eb6df;
  color: #ffffff;
  transition: 200ms;
}

/*----- Confirm Code Redeem -----*/
.modal-container-redeem[data-v-8c29f75c] {
  width: 350px;
  height: 400px;

  display: flex;
  flex-direction: column;
  align-items: flex-start;

  padding: 20px;
  padding-left: 30px;
  padding-right: 30px;

  background-color: white;
  border-radius: 15px;
}
.modal-bottom-redeem[data-v-8c29f75c] {
  width: 100%;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  flex-grow: 1;

  margin-top: 30px;
}
.modal-icon[data-v-8c29f75c] {
  width: 200px;
  -webkit-filter: drop-shadow(0px 0px 10px rgba(0, 0, 0, 0.2));
          filter: drop-shadow(0px 0px 10px rgba(0, 0, 0, 0.2));
}
.expiration[data-v-8c29f75c] {
  display: flex;
  flex-direction: row;

  color: #707070;
}
.btt-ok[data-v-8c29f75c] {
  width: 100%;
  height: 30px;

  display: flex;
  align-items: center;
  justify-content: center;

  background-color: #f3f3f3;
  font-weight: 600;
  border-radius: 5px;
  cursor: pointer;
}
.btt-ok[data-v-8c29f75c]:hover {
  background-color: #eeeeee;
  transition: 200ms;
}

.page-container[data-v-f06a78ff] {
  width: 75%;
  height: 85%;
  max-width: 1600px;

  display: flex;
  flex-direction: row;
}

/*-----------LEFT UI----------*/
.UI-left[data-v-f06a78ff] {
  display: flex;
  flex-direction: column;

  width: 70%;
  margin-right: 20px;
  padding-top: 20px;
  padding-bottom: 20px;

  border-radius: 10px;
  background-color: #ffffff;
}
.UI-left-top[data-v-f06a78ff] {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 10px;
  margin-bottom: 5px;

  padding-left: 30px;
  padding-right: 30px;
}
.no-stats-box[data-v-f06a78ff] {
  width: 100%;
  height: 100%;

  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;

  font-size: 20px;
  font-weight: 600;
  color: #bcbcbc;

  background-color: #f7f7f7;
  border-radius: 10px;
}

/*Header*/
.header-pair[data-v-f06a78ff] {
  display: flex;
  flex-direction: row;
  align-items: center;

  margin-bottom: 10px;
}
.header-right[data-v-f06a78ff] {
  display: flex;
  flex-direction: column;
}
.header-right-heading[data-v-f06a78ff] {
  font-size: 18px;
  font-weight: 600;
  color: #3c8cc9;
  margin-left: 15px;
}
.header-right-subheading[data-v-f06a78ff] {
  font-size: 14px;
  font-weight: 500;
  color: #bcbcbc;
  margin-left: 15px;
}
.header-icon[data-v-f06a78ff] {
  fill: #3c8cc9;
}

/*Statbars*/
.statbars-container[data-v-f06a78ff] {
  height: 100%;
  display: flex;
  flex-direction: column;

  padding-left: 30px;
  padding-right: 30px;

  overflow-y: auto;
}
.statbars-container[data-v-f06a78ff]:hover::-webkit-scrollbar-track {
  background: rgba(241, 241, 241, 1);
  opacity: 100%;
  -webkit-transition: 200ms;
  transition: 200ms;
}
.statbars-container[data-v-f06a78ff]:hover::-webkit-scrollbar-thumb {
  background: rgba(220, 220, 220, 1);
  opacity: 100%;
  -webkit-transition: 200ms;
  transition: 200ms;
}
.statbar-container[data-v-f06a78ff] {
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  width: 100%;
  display: flex;
  flex-direction: column;

  margin-top: 15px;
}
.delname[data-v-f06a78ff] {
  font-size: 16px;
  font-weight: 500;
  margin-left: 5px;
  margin-bottom: 5px;
}
.bar[data-v-f06a78ff] {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 20px;
  overflow: hidden;
  background-color: #f7f7f7;
  border-radius: 10px;
}
.passbar[data-v-f06a78ff] {
  height: 100%;
  background-color: #3c8cc9;
  border-radius: 10px 0px 0px 10px;

  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.passbar-label[data-v-f06a78ff] {
  font-size: 14px;
  font-weight: 400;
  color: #ffffff;

  margin-left: 8px;
  margin-right: 8px;
}
.failbar[data-v-f06a78ff] {
  height: 100%;
  background-color: #bae4fa;
  border-radius: 0px 10px 10px 0px;

  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.failbar-label[data-v-f06a78ff] {
  font-size: 14px;
  font-weight: 400;

  margin-left: 8px;
  margin-right: 8px;
}

/*-----------RIGHT UI----------*/
.UI-right[data-v-f06a78ff] {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}
.UI-right-block[data-v-f06a78ff] {
  width: 100%;
  height: 200px;

  display: flex;
  flex-direction: column;

  padding-top: 20px;
  padding-bottom: 20px;
  padding-left: 30px;
  padding-right: 30px;
  margin-bottom: 20px;

  border-radius: 10px;
  background-color: #ffffff;
}

/*Processed Counter*/
.number-container[data-v-f06a78ff] {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-start;
}
.big-counter[data-v-f06a78ff] {
  font-size: 64px;
  font-weight: 700;
}
.small-counter[data-v-f06a78ff] {
  font-size: 18px;
  font-weight: 500;
  color: #bcbcbc;
}

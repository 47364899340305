.page-container[data-v-e73b4717] {
  width: 75%;
  height: 85%;
  max-width: 1600px;

  display: flex;
  flex-direction: row;
}
.hidden[data-v-e73b4717] {
  display: none;
}
.super-z[data-v-e73b4717] {
  z-index: 10;
}

/*-----------LEFT UI----------*/
.UI-left[data-v-e73b4717] {
  display: flex;
  flex-direction: column;

  width: 70%;
  margin-right: 20px;
  padding-top: 20px;
  padding-bottom: 20px;

  border-radius: 10px;
  background-color: #ffffff;
}
.UI-left-top[data-v-e73b4717] {
  display: flex;
  flex-direction: column;
  padding-left: 30px;
  padding-right: 30px;
}
.notes-container[data-v-e73b4717] {
  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: column;

  margin-top: 20px;
  padding-left: 30px;
  padding-right: 30px;

  overflow-y: auto;
}
.notes-container[data-v-e73b4717]:hover::-webkit-scrollbar-track {
  background: rgba(241, 241, 241, 1);
  opacity: 100%;
  -webkit-transition: 200ms;
  transition: 200ms;
}
.notes-container[data-v-e73b4717]:hover::-webkit-scrollbar-thumb {
  background: rgba(220, 220, 220, 1);
  opacity: 100%;
  -webkit-transition: 200ms;
  transition: 200ms;
}
.no-notes-box[data-v-e73b4717] {
  width: 100%;
  height: 100%;

  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;

  font-size: 20px;
  font-weight: 600;
  color: #bcbcbc;

  background-color: #f7f7f7;
  border-radius: 10px;
}

/*Header*/
.header-pair[data-v-e73b4717] {
  display: flex;
  flex-direction: row;
  align-items: center;

  margin-bottom: 10px;
}
.header-text[data-v-e73b4717] {
  margin-left: 15px;
  font-size: 18px;
  font-weight: 600;
  color: #3c8cc9;
}
.header-icon[data-v-e73b4717] {
  width: 30px;
  height: 30px;
  fill: #3c8cc9;
}
.btt-export-notes[data-v-e73b4717] {
  width: 100%;

  display: flex;
  justify-content: center;
  align-items: center;

  font-size: 14px;
  font-weight: 600;

  padding: 8px;
  padding-left: 25px;
  padding-right: 25px;
  margin-top: 15px;

  background-color: #eeeeee;
  border-radius: 10px;
  cursor: pointer;

  -webkit-user-select: none; /* Safari */ /* Firefox */ /* IE10+/Edge */
  user-select: none; /* Standard */
}
.btt-export-notes[data-v-e73b4717]:hover {
  background-color: #d9d9d9;
  transition: 200ms;
}
.btt-export-notes-inner[data-v-e73b4717] {
  width: 165px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

/*Searchbar*/
.searchbar[data-v-e73b4717] {
  position: relative;
  width: 350px;
}
.subbar[data-v-e73b4717] {
  width: 100%;
  height: 30px;
  position: relative;
  z-index: 2;
  display: flex;
  align-items: center;

  padding-left: 15px;
  padding-right: 30px;

  font-size: 16px;

  outline: none !important;
  border-color: rgba(0, 0, 0, 0);
  border-radius: 10px;
  background-color: #f7f7f7;
}
.subbar[data-v-e73b4717]::-webkit-input-placeholder {
  font-size: 16px;
  color: #c0c0c0;
}
.subbar[data-v-e73b4717]::placeholder {
  font-size: 16px;
  color: #c0c0c0;
}
.subbar[data-v-e73b4717]:focus {
  outline: none !important;
  border-color: rgba(0, 0, 0, 0);
  background-color: #eeeeee;
}
.subbar[data-v-e73b4717]:hover {
  background-color: #eeeeee;
}
.icon[data-v-e73b4717] {
  position: absolute;
  z-index: 2;
  right: 10px;
  top: 8px;
  fill: #bcbcbc;
}
.drop-field[data-v-e73b4717] {
  width: 100%;
  max-height: 200px;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  overflow-y: hidden;

  padding-top: 35px;
  padding-bottom: 10px;
  font-size: 14px;

  border-color: rgba(46, 18, 18, 0);
  border-radius: 10px 10px 5px 5px;
  background-color: #f7f7f7;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
}
.subdropfield[data-v-e73b4717] {
  width: 100%;
  max-height: 165px;
  overflow-y: auto;
}
.subdropfield[data-v-e73b4717]:hover::-webkit-scrollbar-track {
  background: rgba(241, 241, 241, 1);
  opacity: 100%;
  -webkit-transition: 200ms;
  transition: 200ms;
}
.subdropfield[data-v-e73b4717]:hover::-webkit-scrollbar-thumb {
  background: rgba(220, 220, 220, 1);
  opacity: 100%;
  -webkit-transition: 200ms;
  transition: 200ms;
}
.selections-container[data-v-e73b4717] {
  margin-top: 10px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
.selection[data-v-e73b4717] {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  padding: 3px;
  padding-left: 10px;
  padding-right: 10px;
  margin-right: 5px;
  margin-bottom: 3px;

  display: flex;
  justify-content: center;
  align-items: center;

  border-radius: 5px;
  background-color: #bae4fa;
  color: #00a3ff;

  font-size: 10px;
  cursor: pointer;

  -webkit-user-select: none; /* Safari */ /* Firefox */ /* IE10+/Edge */
  user-select: none; /* Standard */
}
.selection[data-v-e73b4717]:hover {
  background-color: #7eb6df;
  color: #ffffff;
  transition: 200ms;
}
.dropdown-option-container[data-v-e73b4717] {
  width: 100%;
  height: 28px;
  padding: 5px;
  padding-left: 25px;
  padding-right: 25px;
  overflow-x: hidden;
  cursor: pointer;

  -webkit-user-select: none; /* Safari */ /* Firefox */ /* IE10+/Edge */
  user-select: none; /* Standard */
}
.dropdown-option-container[data-v-e73b4717]:hover {
  background-color: #eeeeee;
}
.dropdown-text-container[data-v-e73b4717] {
  overflow: hidden;
}
.dropdown-defocuser[data-v-e73b4717] {
  width: 100vw;
  height: 100vh;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 2;
}

/*Noteblock*/
.noteblock-container[data-v-e73b4717] {
  width: 100%;
  margin-bottom: 20px;

  display: flex;
  flex-direction: column;
}
.noteblock-title[data-v-e73b4717] {
  font-size: 18px;
  font-weight: 500;

  margin-left: 5px;
  margin-bottom: 10px;
}
.noteblock-textfield[data-v-e73b4717] {
  flex-grow: 1;
  min-height: 150px;

  background-color: #f7f7f7;
  border-radius: 10px;

  padding: 10px;
  padding-top: 5px;

  font-size: 16px;
  font-family: "Inter", sans-serif;

  border-color: rgba(0, 0, 0, 0);
  border-radius: 10px;
  background-color: #f7f7f7;

  resize: vertical;
  overflow-y: auto;
}
.noteblock-textfield[data-v-e73b4717]::-webkit-input-placeholder {
  font-size: 16px;
  color: #c0c0c0;
}
.noteblock-textfield[data-v-e73b4717]::placeholder {
  font-size: 16px;
  color: #c0c0c0;
}
.noteblock-textfield[data-v-e73b4717]:focus {
  outline: none !important;
  border-color: rgba(0, 0, 0, 0);
  background-color: #f7f7f7;
}
.noteblock-textfield[data-v-e73b4717]:hover {
  background-color: #eeeeee;
}
.noteblock-textfield[data-v-e73b4717]::-webkit-scrollbar-track {
  background: rgba(241, 241, 241, 1);
  opacity: 100%;
  -webkit-transition: 200ms;
  transition: 200ms;
}
.noteblock-textfield[data-v-e73b4717]::-webkit-scrollbar-thumb {
  background: rgba(220, 220, 220, 1);
  opacity: 100%;
  -webkit-transition: 200ms;
  transition: 200ms;
}

/*-----------RIGHT UI----------*/
.UI-right[data-v-e73b4717] {
  display: flex;
  flex-direction: column;
  flex-grow: 1;

  padding-top: 20px;
  padding-bottom: 20px;
  padding-left: 30px;
  padding-right: 30px;

  border-radius: 10px;
  background-color: #ffffff;
}
.UI-right-input[data-v-e73b4717] {
  flex-grow: 1;

  background-color: #f7f7f7;
  border-radius: 10px;

  margin-top: 10px;
  padding: 10px;
  padding-top: 5px;

  font-size: 16px;
  font-family: "Inter", sans-serif;

  border-color: rgba(108, 54, 54, 0);
  border-radius: 10px;
  background-color: #f7f7f7;

  resize: none;
  overflow-y: auto;
}
.UI-right-input[data-v-e73b4717]::-webkit-input-placeholder {
  font-size: 16px;
  color: #c0c0c0;
}
.UI-right-input[data-v-e73b4717]::placeholder {
  font-size: 16px;
  color: #c0c0c0;
}
.UI-right-input[data-v-e73b4717]:focus {
  outline: none !important;
  border-color: rgba(0, 0, 0, 0);
  background-color: #f7f7f7;
}
.UI-right-input[data-v-e73b4717]:hover {
  background-color: #eeeeee;
}
.UI-right-input[data-v-e73b4717]:hover::-webkit-scrollbar-track {
  background: rgba(241, 241, 241, 1);
  opacity: 100%;
  -webkit-transition: 200ms;
  transition: 200ms;
}
.UI-right-input[data-v-e73b4717]:hover::-webkit-scrollbar-thumb {
  background: rgba(220, 220, 220, 1);
  opacity: 100%;
  -webkit-transition: 200ms;
  transition: 200ms;
}

/*----- Bricked Button -----*/
.btt-bricked[data-v-e73b4717] {
  width: 100%;

  display: flex;
  justify-content: center;
  align-items: center;

  font-size: 14px;
  font-weight: 600;

  padding: 8px;
  padding-left: 25px;
  padding-right: 25px;
  margin-top: 15px;

  background-color: #f3f3f3;
  color: #bcbcbc;
  border-radius: 10px;

  -webkit-user-select: none; /* Safari */ /* Firefox */ /* IE10+/Edge */
  user-select: none; /* Standard */
}

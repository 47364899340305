.container[data-v-9d42a6fd] {
  position: absolute;
  top: 0;
  right: 0;

  display: flex;
  flex-direction: column;
  align-items: flex-end;
}
.indicator[data-v-9d42a6fd] {
  min-width: 70px;

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  padding: 5px;
  padding-left: 10px;
  padding-right: 10px;

  color: white;
  font-size: 12px;
  font-weight: 500;
  border-radius: 0 10px 0 10px;

  -webkit-user-select: none; /* Safari */ /* Firefox */ /* IE10+/Edge */
  user-select: none; /* Standard */
}
.cursor-pointer[data-v-9d42a6fd] {
  cursor: pointer;
}

/*----- Support Hovering -----*/
.passed[data-v-9d42a6fd] {
  background-color: #1ed760;
}
.failed[data-v-9d42a6fd] {
  background-color: #ff7070;
}
.pending[data-v-9d42a6fd] {
  background-color: #bcbcbc;
}
.draft[data-v-9d42a6fd] {
  background-color: #bcbcbc;
}
.hover-passed[data-v-9d42a6fd]:hover {
  background-color: #0ac34c;
  transition: 200ms;
}
.hover-failed[data-v-9d42a6fd]:hover {
  background-color: #eb5c5c;
  transition: 200ms;
}

/*----- Feedback -----*/
.feedback[data-v-9d42a6fd] {
  max-width: 300px;

  background-color: white;
  font-size: 14px;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}
.feedback-heading[data-v-9d42a6fd] {
  width: 100%;
  padding: 8px;
  padding-left: 15px;
  padding-right: 15px;

  background-color: #f3f3f3;

  font-weight: 500;
  color: black;
  border-radius: 5px 0 0 0;
}
.feedback-text[data-v-9d42a6fd] {
  max-height: 400px;
  overflow-y: auto;

  padding: 10px;
  padding-left: 15px;
  padding-right: 15px;

  white-space: pre-line;
}
.feedback-text[data-v-9d42a6fd]::-webkit-scrollbar-track {
  background: rgba(241, 241, 241, 1);
  opacity: 100%;
  -webkit-transition: 200ms;
  transition: 200ms;
}
.feedback-text[data-v-9d42a6fd]::-webkit-scrollbar-thumb {
  background: rgba(220, 220, 220, 1);
  opacity: 100%;
  -webkit-transition: 200ms;
  transition: 200ms;
}

.dashboard-container[data-v-dcd0b0c2] {
  width: 100%;
  height: 100%;
  position: relative;

  display: flex;
  flex-direction: column;
  justify-content: space-between;

  background-color: #f7f7f7;
  overflow: auto;
}
.dashboard-container[data-v-dcd0b0c2]::-webkit-scrollbar {
  display: none;
}
.content-container[data-v-dcd0b0c2] {
  display: flex;
  flex-direction: row;
  flex-grow: 1;
}
.form-container[data-v-dcd0b0c2] {
  width: 90%;
  max-width: 700px;
  display: flex;
  flex-direction: column;
  margin-top: 30px;
}
.hide[data-v-dcd0b0c2] {
  display: none;
}

/*--------UI FORMATTING--------*/
.UI-left[data-v-dcd0b0c2] {
  display: flex;
  justify-content: center;
  flex-grow: 1;
  position: relative;
  overflow-y: auto;
}
.UI-left[data-v-dcd0b0c2]::-webkit-scrollbar {
  display: none;
}

/*---------HAT---------*/
.form-top[data-v-dcd0b0c2] {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.preview-hat[data-v-dcd0b0c2] {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 30px;
}
.preview-hat-heading[data-v-dcd0b0c2] {
  max-width: 400px;
  font-size: 32px;
  font-weight: 600;
  color: #bcbcbc;
}
.preview-hat-subheading[data-v-dcd0b0c2] {
  font-size: 18px;
  font-weight: 500;
  color: #bcbcbc;
}
.btt-signout[data-v-dcd0b0c2] {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  display: flex;
  align-items: center;
  justify-content: center;

  font-size: 14px;
  font-weight: 600;

  margin-top: 5px;
  padding: 8px;
  padding-left: 25px;
  padding-right: 25px;

  background-color: #4b6980;
  color: #ffffff;
  border-radius: 10px;
  cursor: pointer;
}
.btt-signout[data-v-dcd0b0c2]:hover {
  background-color: #6c92af;
  color: #ffffff;
  transition: 200ms;
}

/*----CONFIRMATION PAGE----*/
.submission-confirmation[data-v-dcd0b0c2] {
  height: 100%;
  width: 100%;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  background-color: #f7f7f7;
}
.submission-confirmation-top[data-v-dcd0b0c2] {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 20px;

  font-size: 38px;
  font-weight: 700;
  color: #707070;
}
.confirmation-icon[data-v-dcd0b0c2] {
  fill: #00a3ff;
  margin-right: 30px;
}
.btt-new-directive[data-v-dcd0b0c2] {
  padding: 10px;
  padding-left: 20px;
  padding-right: 20px;

  display: flex;
  justify-content: center;
  align-items: center;

  border-radius: 10px;
  background-color: #bae4fa;
  color: #00a3ff;
  transition: 200ms;

  font-size: 14px;
  font-weight: 600;

  cursor: pointer;
  -webkit-user-select: none; /* Safari */ /* Firefox */ /* IE10+/Edge */
  user-select: none; /* Standard */
}
.btt-new-directive[data-v-dcd0b0c2]:hover {
  background-color: #7eb6df;
  color: #ffffff;
  transition: 200ms;
}
@media (max-width: 641px) {
.form-top[data-v-dcd0b0c2] {
    padding-left: 10px;
    padding-right: 10px;
    flex-direction: column;
    margin-bottom: 30px;
}
.preview-hat[data-v-dcd0b0c2] {
    margin-bottom: 10px;
}
.submission-confirmation-heading[data-v-dcd0b0c2] {
    width: 170px;
    font-size: 30px;
    margin-left: 10px;
}
.confirmation-icon[data-v-dcd0b0c2] {
    height: 40px;
    margin-right: 10px;
}
.btt-new-directive[data-v-dcd0b0c2] {
    font-size: 12px;
}
}

.options-container[data-v-8f9641d1] {
  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

/*---------------TOP----------------*/
.top[data-v-8f9641d1] {
  width: 100%;
  padding-top: 80px;
  padding-bottom: 100px;

  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #f5f8fb;
}
.top-container[data-v-8f9641d1] {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.header[data-v-8f9641d1] {
  font-size: 36px;
  font-weight: 700;
  color: #4a687f;
  margin-bottom: 20px;
}

/*---------------BOTTOM---------------*/
.bottom[data-v-8f9641d1] {
  width: 100%;
  padding-top: 80px;
  padding-bottom: 100px;

  display: flex;
  align-items: center;
  justify-content: center;
}
.bottom-container[data-v-8f9641d1] {
  width: 900px;
  display: flex;
  flex-direction: column;
}
.bottom-header[data-v-8f9641d1] {
  font-size: 26px;
  font-weight: 700;
  color: #707070;
  margin-bottom: 20px;
}
.nowrap[data-v-8f9641d1] {
  display: flex;
  flex-direction: row;
}
.bottom-disclaimer[data-v-8f9641d1] {
  display: flex;
  flex-direction: row;
  margin-top: 20px;
}
.red-highlight[data-v-8f9641d1] {
  color: #940000;
  text-decoration: none;
}

/*----JOIN PRIVATE BETA----*/
.join-container[data-v-8f9641d1] {
  display: flex;
  flex-direction: column;

  padding: 50px;

  width: 100%;
  position: absolute;
  top: 100px;
  z-index: 2;

  background-color: #f7f7f7;
  box-shadow: -10px 10px 30px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
}
.join-header[data-v-8f9641d1] {
  font-size: 24px;
  font-weight: 700;
  color: #4a6980;
}
.join-subheader[data-v-8f9641d1] {
  font-size: 18px;
  font-weight: 600;
  color: #707070;
}
.join-action[data-v-8f9641d1] {
  font-size: 18px;
  font-weight: 600;
  margin-top: 20px;
}
.link[data-v-8f9641d1] {
  color: #00a4ff;
  text-decoration: none;
}

/*----- Trusted By -----*/
.trusted-by[data-v-8f9641d1] {
  display: flex;
  flex-direction: column;
  margin-bottom: 50px;

  -webkit-user-select: none; /* Safari */ /* Firefox */ /* IE10+/Edge */
  user-select: none; /* Standard */
}
.logos[data-v-8f9641d1] {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 20px;
  gap: 20px;
}
.trusted-text[data-v-8f9641d1] {
  font-size: 14px;
  font-weight: 500;
  color: #707070;
  margin-bottom: 10px;
}
.logo[data-v-8f9641d1] {
  height: 45px;
  -webkit-filter: grayscale(100%) brightness(130%);
          filter: grayscale(100%) brightness(130%);
}

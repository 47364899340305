.eula-container[data-v-592ec01d] {
  width: 100%;

  display: flex;
  justify-content: center;
}
.eula-content[data-v-592ec01d] {
  width: 60%;
  margin: 70px;
}

/* cspell:disable-file */
/* webkit printing magic: print all background colors */
html[data-v-592ec01d] {
  -webkit-print-color-adjust: exact;
}
*[data-v-592ec01d] {
  box-sizing: border-box;
  -webkit-print-color-adjust: exact;
}
html[data-v-592ec01d],
body[data-v-592ec01d] {
  margin: 0;
  padding: 0;
}
@media only screen {
body[data-v-592ec01d] {
    margin: 2em auto;
    max-width: 900px;
    color: rgb(55, 53, 47);
}
}
body[data-v-592ec01d] {
  line-height: 1.5;
  white-space: pre-wrap;
}
a[data-v-592ec01d],
a.visited[data-v-592ec01d] {
  color: inherit;
  text-decoration: underline;
}
.pdf-relative-link-path[data-v-592ec01d] {
  font-size: 80%;
  color: #444;
}
h1[data-v-592ec01d],
h2[data-v-592ec01d],
h3[data-v-592ec01d] {
  letter-spacing: -0.01em;
  line-height: 1.2;
  font-weight: 600;
  margin-bottom: 0;
}
.page-title[data-v-592ec01d] {
  font-size: 2.5rem;
  font-weight: 700;
  margin-top: 0;
  margin-bottom: 0.75em;
  color: #47667d;
}
h1[data-v-592ec01d] {
  font-size: 1.875rem;
  margin-top: 1.875rem;
}
h2[data-v-592ec01d] {
  font-size: 1.5rem;
  margin-top: 1.5rem;
}
h3[data-v-592ec01d] {
  font-size: 1.25rem;
  margin-top: 1.25rem;
}
.source[data-v-592ec01d] {
  border: 1px solid #ddd;
  border-radius: 3px;
  padding: 1.5em;
  word-break: break-all;
}
.callout[data-v-592ec01d] {
  border-radius: 3px;
  padding: 1rem;
}
figure[data-v-592ec01d] {
  margin: 1.25em 0;
  page-break-inside: avoid;
}
figcaption[data-v-592ec01d] {
  opacity: 0.5;
  font-size: 85%;
  margin-top: 0.5em;
}
mark[data-v-592ec01d] {
  background-color: transparent;
}
.indented[data-v-592ec01d] {
  padding-left: 1.5em;
}
hr[data-v-592ec01d] {
  background: transparent;
  display: block;
  width: 100%;
  height: 1px;
  visibility: visible;
  border: none;
  border-bottom: 1px solid rgba(55, 53, 47, 0.09);
}
img[data-v-592ec01d] {
  max-width: 100%;
}
@media only print {
img[data-v-592ec01d] {
    max-height: 100vh;
    object-fit: contain;
}
}
@page {
  margin: 1in;
}
.collection-content[data-v-592ec01d] {
  font-size: 0.875rem;
}
.column-list[data-v-592ec01d] {
  display: flex;
  justify-content: space-between;
}
.column[data-v-592ec01d] {
  padding: 0 1em;
}
.column[data-v-592ec01d]:first-child {
  padding-left: 0;
}
.column[data-v-592ec01d]:last-child {
  padding-right: 0;
}
.table_of_contents-item[data-v-592ec01d] {
  display: block;
  font-size: 0.875rem;
  line-height: 1.3;
  padding: 0.125rem;
}
.table_of_contents-indent-1[data-v-592ec01d] {
  margin-left: 1.5rem;
}
.table_of_contents-indent-2[data-v-592ec01d] {
  margin-left: 3rem;
}
.table_of_contents-indent-3[data-v-592ec01d] {
  margin-left: 4.5rem;
}
.table_of_contents-link[data-v-592ec01d] {
  text-decoration: none;
  opacity: 0.7;
  border-bottom: 1px solid rgba(55, 53, 47, 0.18);
}
table[data-v-592ec01d],
th[data-v-592ec01d],
td[data-v-592ec01d] {
  border: 1px solid rgba(55, 53, 47, 0.09);
  border-collapse: collapse;
}
table[data-v-592ec01d] {
  border-left: none;
  border-right: none;
}
th[data-v-592ec01d],
td[data-v-592ec01d] {
  font-weight: normal;
  padding: 0.25em 0.5em;
  line-height: 1.5;
  min-height: 1.5em;
  text-align: left;
}
th[data-v-592ec01d] {
  color: rgba(55, 53, 47, 0.6);
}
ol[data-v-592ec01d],
ul[data-v-592ec01d] {
  margin: 0;
  -webkit-margin-before: 0.6em;
          margin-block-start: 0.6em;
  -webkit-margin-after: 0.6em;
          margin-block-end: 0.6em;
}
li > ol[data-v-592ec01d]:first-child,
li > ul[data-v-592ec01d]:first-child {
  -webkit-margin-before: 0.6em;
          margin-block-start: 0.6em;
}
ul > li[data-v-592ec01d] {
  list-style: disc;
}
ul.to-do-list[data-v-592ec01d] {
  -webkit-padding-start: 0;
          padding-inline-start: 0;
}
ul.to-do-list > li[data-v-592ec01d] {
  list-style: none;
}
.to-do-children-checked[data-v-592ec01d] {
  text-decoration: line-through;
  opacity: 0.375;
}
ul.toggle > li[data-v-592ec01d] {
  list-style: none;
}
ul[data-v-592ec01d] {
  -webkit-padding-start: 1.7em;
          padding-inline-start: 1.7em;
}
ul > li[data-v-592ec01d] {
  padding-left: 0.1em;
}
ol[data-v-592ec01d] {
  -webkit-padding-start: 1.6em;
          padding-inline-start: 1.6em;
}
ol > li[data-v-592ec01d] {
  padding-left: 0.2em;
}
.mono ol[data-v-592ec01d] {
  -webkit-padding-start: 2em;
          padding-inline-start: 2em;
}
.mono ol > li[data-v-592ec01d] {
  text-indent: -0.4em;
}
.toggle[data-v-592ec01d] {
  -webkit-padding-start: 0em;
          padding-inline-start: 0em;
  list-style-type: none;
}

/* Indent toggle children */
.toggle > li > details[data-v-592ec01d] {
  padding-left: 1.7em;
}
.toggle > li > details > summary[data-v-592ec01d] {
  margin-left: -1.1em;
}
.selected-value[data-v-592ec01d] {
  display: inline-block;
  padding: 0 0.5em;
  background: rgba(206, 205, 202, 0.5);
  border-radius: 3px;
  margin-right: 0.5em;
  margin-top: 0.3em;
  margin-bottom: 0.3em;
  white-space: nowrap;
}
.collection-title[data-v-592ec01d] {
  display: inline-block;
  margin-right: 1em;
}
.simple-table[data-v-592ec01d] {
  margin-top: 1em;
  font-size: 0.875rem;
  empty-cells: show;
}
.simple-table td[data-v-592ec01d] {
  height: 29px;
  min-width: 120px;
}
.simple-table th[data-v-592ec01d] {
  height: 29px;
  min-width: 120px;
}
.simple-table-header-color[data-v-592ec01d] {
  background: rgb(247, 246, 243);
  color: black;
}
.simple-table-header[data-v-592ec01d] {
  font-weight: 500;
}
time[data-v-592ec01d] {
  opacity: 0.5;
}
.icon[data-v-592ec01d] {
  display: inline-block;
  max-width: 1.2em;
  max-height: 1.2em;
  text-decoration: none;
  vertical-align: text-bottom;
  margin-right: 0.5em;
}
img.icon[data-v-592ec01d] {
  border-radius: 3px;
}
.user-icon[data-v-592ec01d] {
  width: 1.5em;
  height: 1.5em;
  border-radius: 100%;
  margin-right: 0.5rem;
}
.user-icon-inner[data-v-592ec01d] {
  font-size: 0.8em;
}
.text-icon[data-v-592ec01d] {
  border: 1px solid #000;
  text-align: center;
}
.page-cover-image[data-v-592ec01d] {
  display: block;
  object-fit: cover;
  width: 100%;
  max-height: 30vh;
}
.page-header-icon[data-v-592ec01d] {
  font-size: 3rem;
  margin-bottom: 1rem;
}
.page-header-icon-with-cover[data-v-592ec01d] {
  margin-top: -0.72em;
  margin-left: 0.07em;
}
.page-header-icon img[data-v-592ec01d] {
  border-radius: 3px;
}
.link-to-page[data-v-592ec01d] {
  margin: 1em 0;
  padding: 0;
  border: none;
  font-weight: 500;
}
p > .user[data-v-592ec01d] {
  opacity: 0.5;
}
td > .user[data-v-592ec01d],
td > time[data-v-592ec01d] {
  white-space: nowrap;
}
input[type="checkbox"][data-v-592ec01d] {
  -webkit-transform: scale(1.5);
          transform: scale(1.5);
  margin-right: 0.6em;
  vertical-align: middle;
}
p[data-v-592ec01d] {
  margin-top: 0.5em;
  margin-bottom: 0.5em;
}
.image[data-v-592ec01d] {
  border: none;
  margin: 1.5em 0;
  padding: 0;
  border-radius: 0;
  text-align: center;
}
.code[data-v-592ec01d],
code[data-v-592ec01d] {
  background: rgba(135, 131, 120, 0.15);
  border-radius: 3px;
  padding: 0.2em 0.4em;
  border-radius: 3px;
  font-size: 85%;
  tab-size: 2;
}
code[data-v-592ec01d] {
  color: #eb5757;
}
.code[data-v-592ec01d] {
  padding: 1.5em 1em;
}
.code-wrap[data-v-592ec01d] {
  white-space: pre-wrap;
  word-break: break-all;
}
.code > code[data-v-592ec01d] {
  background: none;
  padding: 0;
  font-size: 100%;
  color: inherit;
}
blockquote[data-v-592ec01d] {
  font-size: 1.25em;
  margin: 1em 0;
  padding-left: 1em;
  border-left: 3px solid rgb(55, 53, 47);
}
.bookmark[data-v-592ec01d] {
  text-decoration: none;
  max-height: 8em;
  padding: 0;
  display: flex;
  width: 100%;
  align-items: stretch;
}
.bookmark-title[data-v-592ec01d] {
  font-size: 0.85em;
  overflow: hidden;
  text-overflow: ellipsis;
  height: 1.75em;
  white-space: nowrap;
}
.bookmark-text[data-v-592ec01d] {
  display: flex;
  flex-direction: column;
}
.bookmark-info[data-v-592ec01d] {
  flex: 4 1 180px;
  padding: 12px 14px 14px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.bookmark-image[data-v-592ec01d] {
  width: 33%;
  flex: 1 1 180px;
  display: block;
  position: relative;
  object-fit: cover;
  border-radius: 1px;
}
.bookmark-description[data-v-592ec01d] {
  color: rgba(55, 53, 47, 0.6);
  font-size: 0.75em;
  overflow: hidden;
  max-height: 4.5em;
  word-break: break-word;
}
.bookmark-href[data-v-592ec01d] {
  font-size: 0.75em;
  margin-top: 0.25em;
}
.sans[data-v-592ec01d] {
  font-family: ui-sans-serif, -apple-system, BlinkMacSystemFont, "Segoe UI",
    Helvetica, "Apple Color Emoji", Arial, sans-serif, "Segoe UI Emoji",
    "Segoe UI Symbol";
}
.code[data-v-592ec01d] {
  font-family: "SFMono-Regular", Menlo, Consolas, "PT Mono", "Liberation Mono",
    Courier, monospace;
}
.serif[data-v-592ec01d] {
  font-family: Lyon-Text, Georgia, ui-serif, serif;
}
.mono[data-v-592ec01d] {
  font-family: iawriter-mono, Nitti, Menlo, Courier, monospace;
}
.pdf .sans[data-v-592ec01d] {
  font-family: Inter, ui-sans-serif, -apple-system, BlinkMacSystemFont,
    "Segoe UI", Helvetica, "Apple Color Emoji", Arial, sans-serif,
    "Segoe UI Emoji", "Segoe UI Symbol", "Twemoji", "Noto Color Emoji",
    "Noto Sans CJK JP";
}
.pdf:lang(zh-CN) .sans[data-v-592ec01d] {
  font-family: Inter, ui-sans-serif, -apple-system, BlinkMacSystemFont,
    "Segoe UI", Helvetica, "Apple Color Emoji", Arial, sans-serif,
    "Segoe UI Emoji", "Segoe UI Symbol", "Twemoji", "Noto Color Emoji",
    "Noto Sans CJK SC";
}
.pdf:lang(zh-TW) .sans[data-v-592ec01d] {
  font-family: Inter, ui-sans-serif, -apple-system, BlinkMacSystemFont,
    "Segoe UI", Helvetica, "Apple Color Emoji", Arial, sans-serif,
    "Segoe UI Emoji", "Segoe UI Symbol", "Twemoji", "Noto Color Emoji",
    "Noto Sans CJK TC";
}
.pdf:lang(ko-KR) .sans[data-v-592ec01d] {
  font-family: Inter, ui-sans-serif, -apple-system, BlinkMacSystemFont,
    "Segoe UI", Helvetica, "Apple Color Emoji", Arial, sans-serif,
    "Segoe UI Emoji", "Segoe UI Symbol", "Twemoji", "Noto Color Emoji",
    "Noto Sans CJK KR";
}
.pdf .code[data-v-592ec01d] {
  font-family: Source Code Pro, "SFMono-Regular", Menlo, Consolas, "PT Mono",
    "Liberation Mono", Courier, monospace, "Twemoji", "Noto Color Emoji",
    "Noto Sans Mono CJK JP";
}
.pdf:lang(zh-CN) .code[data-v-592ec01d] {
  font-family: Source Code Pro, "SFMono-Regular", Menlo, Consolas, "PT Mono",
    "Liberation Mono", Courier, monospace, "Twemoji", "Noto Color Emoji",
    "Noto Sans Mono CJK SC";
}
.pdf:lang(zh-TW) .code[data-v-592ec01d] {
  font-family: Source Code Pro, "SFMono-Regular", Menlo, Consolas, "PT Mono",
    "Liberation Mono", Courier, monospace, "Twemoji", "Noto Color Emoji",
    "Noto Sans Mono CJK TC";
}
.pdf:lang(ko-KR) .code[data-v-592ec01d] {
  font-family: Source Code Pro, "SFMono-Regular", Menlo, Consolas, "PT Mono",
    "Liberation Mono", Courier, monospace, "Twemoji", "Noto Color Emoji",
    "Noto Sans Mono CJK KR";
}
.pdf .serif[data-v-592ec01d] {
  font-family: PT Serif, Lyon-Text, Georgia, ui-serif, serif, "Twemoji",
    "Noto Color Emoji", "Noto Serif CJK JP";
}
.pdf:lang(zh-CN) .serif[data-v-592ec01d] {
  font-family: PT Serif, Lyon-Text, Georgia, ui-serif, serif, "Twemoji",
    "Noto Color Emoji", "Noto Serif CJK SC";
}
.pdf:lang(zh-TW) .serif[data-v-592ec01d] {
  font-family: PT Serif, Lyon-Text, Georgia, ui-serif, serif, "Twemoji",
    "Noto Color Emoji", "Noto Serif CJK TC";
}
.pdf:lang(ko-KR) .serif[data-v-592ec01d] {
  font-family: PT Serif, Lyon-Text, Georgia, ui-serif, serif, "Twemoji",
    "Noto Color Emoji", "Noto Serif CJK KR";
}
.pdf .mono[data-v-592ec01d] {
  font-family: PT Mono, iawriter-mono, Nitti, Menlo, Courier, monospace,
    "Twemoji", "Noto Color Emoji", "Noto Sans Mono CJK JP";
}
.pdf:lang(zh-CN) .mono[data-v-592ec01d] {
  font-family: PT Mono, iawriter-mono, Nitti, Menlo, Courier, monospace,
    "Twemoji", "Noto Color Emoji", "Noto Sans Mono CJK SC";
}
.pdf:lang(zh-TW) .mono[data-v-592ec01d] {
  font-family: PT Mono, iawriter-mono, Nitti, Menlo, Courier, monospace,
    "Twemoji", "Noto Color Emoji", "Noto Sans Mono CJK TC";
}
.pdf:lang(ko-KR) .mono[data-v-592ec01d] {
  font-family: PT Mono, iawriter-mono, Nitti, Menlo, Courier, monospace,
    "Twemoji", "Noto Color Emoji", "Noto Sans Mono CJK KR";
}
.highlight-default[data-v-592ec01d] {
  color: rgba(55, 53, 47, 1);
}
.highlight-gray[data-v-592ec01d] {
  color: rgba(120, 119, 116, 1);
  fill: rgba(120, 119, 116, 1);
}
.highlight-brown[data-v-592ec01d] {
  color: rgba(159, 107, 83, 1);
  fill: rgba(159, 107, 83, 1);
}
.highlight-orange[data-v-592ec01d] {
  color: rgba(217, 115, 13, 1);
  fill: rgba(217, 115, 13, 1);
}
.highlight-yellow[data-v-592ec01d] {
  color: rgba(203, 145, 47, 1);
  fill: rgba(203, 145, 47, 1);
}
.highlight-teal[data-v-592ec01d] {
  color: rgba(68, 131, 97, 1);
  fill: rgba(68, 131, 97, 1);
}
.highlight-blue[data-v-592ec01d] {
  color: rgba(51, 126, 169, 1);
  fill: rgba(51, 126, 169, 1);
}
.highlight-purple[data-v-592ec01d] {
  color: rgba(144, 101, 176, 1);
  fill: rgba(144, 101, 176, 1);
}
.highlight-pink[data-v-592ec01d] {
  color: rgba(193, 76, 138, 1);
  fill: rgba(193, 76, 138, 1);
}
.highlight-red[data-v-592ec01d] {
  color: rgba(212, 76, 71, 1);
  fill: rgba(212, 76, 71, 1);
}
.highlight-gray_background[data-v-592ec01d] {
  background: rgba(241, 241, 239, 1);
}
.highlight-brown_background[data-v-592ec01d] {
  background: rgba(244, 238, 238, 1);
}
.highlight-orange_background[data-v-592ec01d] {
  background: rgba(251, 236, 221, 1);
}
.highlight-yellow_background[data-v-592ec01d] {
  background: rgba(251, 243, 219, 1);
}
.highlight-teal_background[data-v-592ec01d] {
  background: rgba(237, 243, 236, 1);
}
.highlight-blue_background[data-v-592ec01d] {
  background: rgba(231, 243, 248, 1);
}
.highlight-purple_background[data-v-592ec01d] {
  background: rgba(244, 240, 247, 0.8);
}
.highlight-pink_background[data-v-592ec01d] {
  background: rgba(249, 238, 243, 0.8);
}
.highlight-red_background[data-v-592ec01d] {
  background: rgba(253, 235, 236, 1);
}
.block-color-default[data-v-592ec01d] {
  color: inherit;
  fill: inherit;
}
.block-color-gray[data-v-592ec01d] {
  color: rgba(120, 119, 116, 1);
  fill: rgba(120, 119, 116, 1);
}
.block-color-brown[data-v-592ec01d] {
  color: rgba(159, 107, 83, 1);
  fill: rgba(159, 107, 83, 1);
}
.block-color-orange[data-v-592ec01d] {
  color: rgba(217, 115, 13, 1);
  fill: rgba(217, 115, 13, 1);
}
.block-color-yellow[data-v-592ec01d] {
  color: rgba(203, 145, 47, 1);
  fill: rgba(203, 145, 47, 1);
}
.block-color-teal[data-v-592ec01d] {
  color: rgba(68, 131, 97, 1);
  fill: rgba(68, 131, 97, 1);
}
.block-color-blue[data-v-592ec01d] {
  color: rgba(51, 126, 169, 1);
  fill: rgba(51, 126, 169, 1);
}
.block-color-purple[data-v-592ec01d] {
  color: rgba(144, 101, 176, 1);
  fill: rgba(144, 101, 176, 1);
}
.block-color-pink[data-v-592ec01d] {
  color: rgba(193, 76, 138, 1);
  fill: rgba(193, 76, 138, 1);
}
.block-color-red[data-v-592ec01d] {
  color: rgba(212, 76, 71, 1);
  fill: rgba(212, 76, 71, 1);
}
.block-color-gray_background[data-v-592ec01d] {
  background: rgba(241, 241, 239, 1);
}
.block-color-brown_background[data-v-592ec01d] {
  background: rgba(244, 238, 238, 1);
}
.block-color-orange_background[data-v-592ec01d] {
  background: rgba(251, 236, 221, 1);
}
.block-color-yellow_background[data-v-592ec01d] {
  background: rgba(251, 243, 219, 1);
}
.block-color-teal_background[data-v-592ec01d] {
  background: rgba(237, 243, 236, 1);
}
.block-color-blue_background[data-v-592ec01d] {
  background: rgba(231, 243, 248, 1);
}
.block-color-purple_background[data-v-592ec01d] {
  background: rgba(244, 240, 247, 0.8);
}
.block-color-pink_background[data-v-592ec01d] {
  background: rgba(249, 238, 243, 0.8);
}
.block-color-red_background[data-v-592ec01d] {
  background: rgba(253, 235, 236, 1);
}
.select-value-color-pink[data-v-592ec01d] {
  background-color: rgba(245, 224, 233, 1);
}
.select-value-color-purple[data-v-592ec01d] {
  background-color: rgba(232, 222, 238, 1);
}
.select-value-color-green[data-v-592ec01d] {
  background-color: rgba(219, 237, 219, 1);
}
.select-value-color-gray[data-v-592ec01d] {
  background-color: rgba(227, 226, 224, 1);
}
.select-value-color-opaquegray[data-v-592ec01d] {
  background-color: rgba(255, 255, 255, 0.0375);
}
.select-value-color-orange[data-v-592ec01d] {
  background-color: rgba(250, 222, 201, 1);
}
.select-value-color-brown[data-v-592ec01d] {
  background-color: rgba(238, 224, 218, 1);
}
.select-value-color-red[data-v-592ec01d] {
  background-color: rgba(255, 226, 221, 1);
}
.select-value-color-yellow[data-v-592ec01d] {
  background-color: rgba(253, 236, 200, 1);
}
.select-value-color-blue[data-v-592ec01d] {
  background-color: rgba(211, 229, 239, 1);
}
.checkbox[data-v-592ec01d] {
  display: inline-flex;
  vertical-align: text-bottom;
  width: 16;
  height: 16;
  background-size: 16px;
  margin-left: 2px;
  margin-right: 5px;
}
.checkbox-on[data-v-592ec01d] {
  background-image: url("data:image/svg+xml;charset=UTF-8,%3Csvg%20width%3D%2216%22%20height%3D%2216%22%20viewBox%3D%220%200%2016%2016%22%20fill%3D%22none%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%0A%3Crect%20width%3D%2216%22%20height%3D%2216%22%20fill%3D%22%2358A9D7%22%2F%3E%0A%3Cpath%20d%3D%22M6.71429%2012.2852L14%204.9995L12.7143%203.71436L6.71429%209.71378L3.28571%206.2831L2%207.57092L6.71429%2012.2852Z%22%20fill%3D%22white%22%2F%3E%0A%3C%2Fsvg%3E");
}
.checkbox-off[data-v-592ec01d] {
  background-image: url("data:image/svg+xml;charset=UTF-8,%3Csvg%20width%3D%2216%22%20height%3D%2216%22%20viewBox%3D%220%200%2016%2016%22%20fill%3D%22none%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%0A%3Crect%20x%3D%220.75%22%20y%3D%220.75%22%20width%3D%2214.5%22%20height%3D%2214.5%22%20fill%3D%22white%22%20stroke%3D%22%2336352F%22%20stroke-width%3D%221.5%22%2F%3E%0A%3C%2Fsvg%3E");
}

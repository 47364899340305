.container[data-v-e79252ce] {
  display: flex;
  flex-direction: column;
  margin-top: 20px;
  -webkit-transform: translate(50px);
          transform: translate(50px);
}
.text[data-v-e79252ce] {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;

  font-size: 16px;
  font-weight: 600;
  color: #999999;

  padding: 15px;
  padding-top: 10px;
  padding-bottom: 0px;

  background-color: #ffffff;
  border-radius: 10px 10px 0px 0px;
}
.options-container[data-v-e79252ce] {
  width: 500px;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;

  padding: 10px;
  margin-bottom: 100px;

  background-color: #ffffff;
  border-radius: 0px 10px 10px 10px;

  display: flex;
  flex-wrap: wrap;
}
.btt[data-v-e79252ce] {
  padding: 5px;
  padding-left: 10px;
  padding-right: 10px;
  margin: 5px;

  background-color: #bae4fa;
  border-radius: 10px;

  color: #378dcc;
  font-weight: 500;
  text-align: center;

  display: flex;
  align-items: center;
  justify-content: center;

  cursor: pointer;
}
.btt[data-v-e79252ce]:hover {
  background-color: #7eb6df;
  color: #ffffff;
  transition: 200ms;
}

.container[data-v-2009a244] {
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  display: flex;
  align-items: center;
  cursor: pointer;
}
.bar[data-v-2009a244] {
  position: relative;
  width: 45px;
  height: 20px;

  border-radius: 10px;
}
.circle[data-v-2009a244] {
  position: absolute;
  top: -1px;
  left: 0px;

  width: 22px;
  height: 22px;
  border-radius: 50%;
}
.bgon[data-v-2009a244] {
  background-color: #cff9c3;
  transition: 200ms;
}
.btton[data-v-2009a244] {
  background-color: #3cdc27;
  transition: 200ms;
}
.bgoff[data-v-2009a244] {
  background-color: #eeeeee;
  transition: 200ms;
}
.bttoff[data-v-2009a244] {
  background-color: #aaaaaa;
  transition: 200ms;
}
.label[data-v-2009a244] {
  font-size: 16px;
  font-weight: 500;
  transition: 200ms;
}
.labeloff[data-v-2009a244] {
  color: #707070;
}
.labelon[data-v-2009a244] {
  color: #3cdc27;
}
.noselect[data-v-2009a244] {
  -webkit-user-select: none; /* Safari */ /* Firefox */ /* IE10+/Edge */
  user-select: none; /* Standard */
}

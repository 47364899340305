.page-container[data-v-dc19246d] {
  width: 75%;
  height: 85%;
  max-width: 1600px;

  display: flex;
  flex-direction: row;
}

/*-----------LEFT UI----------*/
.UI-left[data-v-dc19246d] {
  display: flex;
  flex-direction: column;

  min-width: 70%;
  margin-right: 20px;
  padding-top: 20px;
  padding-bottom: 20px;
  padding-left: 30px;
  padding-right: 30px;

  border-radius: 10px;
  background-color: #ffffff;
}
.UI-left-top[data-v-dc19246d] {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
}

/*Header*/
.header-pair[data-v-dc19246d] {
  display: flex;
  flex-direction: row;
  align-items: center;

  margin-bottom: 10px;
}
.header-text[data-v-dc19246d] {
  font-size: 18px;
  font-weight: 600;
  color: #3c8cc9;
  margin-left: 15px;
}
.header-icon[data-v-dc19246d] {
  fill: #3c8cc9;
}

/*Committee Information*/
.setting-header[data-v-dc19246d] {
  font-size: 18px;
  font-weight: 500;
  margin-bottom: 10px;
  color: #707070;
}
.inputs-container[data-v-dc19246d] {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
.input-container[data-v-dc19246d] {
  display: flex;
  flex-direction: column;
  margin-bottom: 15px;
}
.input-subheader[data-v-dc19246d] {
  font-size: 16px;
  font-weight: 500;
}
.input-field[data-v-dc19246d] {
  min-width: 250px;
  height: 30px;

  margin-top: 10px;
  margin-right: 40px;
  padding-left: 10px;
  padding-right: 10px;

  font-size: 16px;

  border-color: rgba(0, 0, 0, 0);
  border-radius: 10px;
  background-color: #f7f7f7;
}
.input-field[data-v-dc19246d]:focus {
  outline: none !important;
  border-color: rgba(0, 0, 0, 0);
  background-color: #f7f7f7;
}
.input-field[data-v-dc19246d]:hover {
  background-color: #eeeeee;
}

/*-----------RIGHT UI----------*/
.UI-right[data-v-dc19246d] {
  display: flex;
  flex-direction: column;
  flex-grow: 1;

  padding-top: 20px;
  padding-bottom: 20px;
  padding-left: 30px;
  padding-right: 30px;

  border-radius: 10px;
  background-color: #ffffff;
}
.release-notes-container[data-v-dc19246d] {
  display: flex;
  flex-direction: column;
  flex-grow: 1;

  background-color: #f7f7f7;
  border-radius: 10px;

  margin-top: 10px;
  padding: 20px;

  font-size: 16px;
  font-family: "Inter", sans-serif;

  border-color: rgba(0, 0, 0, 0);
  border-radius: 10px;
  background-color: #f7f7f7;

  overflow-y: auto;
}
.version-note-heading[data-v-dc19246d] {
  margin-bottom: 5px;
  font-size: 16px;
  font-weight: 500;
}
.version-note-subheading[data-v-dc19246d] {
  font-size: 16px;
  color: #707070;
  margin-bottom: 25px;
}
.metadata[data-v-dc19246d] {
  color: #bcbcbc;
  margin-top: 15px;
}
.signature[data-v-dc19246d] {
  margin-top: 10px;
}

/*----- Clear All -----*/
.btt-clear[data-v-dc19246d] {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;

  display: flex;
  align-items: center;
  justify-content: center;

  font-size: 14px;
  font-weight: 600;
  white-space: nowrap;

  padding: 8px;
  padding-left: 25px;
  padding-right: 25px;
  margin-left: 15px;

  background-color: #f3b9c9;
  color: #ff4343;
  border-radius: 10px;
  cursor: pointer;

  -webkit-user-select: none; /* Safari */ /* Firefox */ /* IE10+/Edge */
  user-select: none; /* Standard */
}
.btt-clear[data-v-dc19246d]:hover {
  background-color: #ff7979;
  color: #ffffff;
  transition: 200ms;
}
.flex-row[data-v-dc19246d] {
  display: flex;
  flex-direction: row;
}
.flex-col[data-v-dc19246d] {
  display: flex;
  flex-direction: column;
}
.danger-container[data-v-dc19246d] {
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  border-width: 2px;
  border-color: #f3f3f3;
  border-style: solid;
  border-radius: 10px;

  padding: 15px;
}

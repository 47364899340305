/*-------GENERIC OPERATIONS-------*/
.hidden[data-v-585d739d] {
  display: none;
}
.fade[data-v-585d739d] {
  opacity: 0%;
}
.nowrap[data-v-585d739d] {
  white-space: nowrap;
}
.clickable[data-v-585d739d] {
  cursor: pointer;
}
.super-z[data-v-585d739d] {
  z-index: 10;
}

/*--------HEADER--------*/
.header-container[data-v-585d739d] {
  width: 700px;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  margin-bottom: 20px;

  position: relative;

  background-color: white;
  border-radius: 10px;
  padding: 15px;
}
.header-image[data-v-585d739d] {
  width: 100%;
  height: 100%;
  min-height: 150px;
  object-fit: cover;
  border-radius: 10px;
  margin-bottom: 15px;
}
.header-text-container[data-v-585d739d] {
  margin-bottom: 5px;
  margin-left: 20px;
  margin-right: 20px;
}
.header-heading[data-v-585d739d] {
  font-size: 24px;
  font-weight: 700;
  margin-bottom: 5px;
}
.header-subheading[data-v-585d739d] {
  font-weight: 500;
  color: #c4c4c4;
}

/*-------BLOCK FORMATTING------*/
.block-container[data-v-585d739d] {
  width: 700px;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  margin-bottom: 10px;
  position: relative;

  background-color: white;
  border-radius: 10px;

  padding: 20px;
  padding-left: 35px;
  padding-right: 35px;
}
.heading[data-v-585d739d] {
  font-weight: 600;
  font-size: 18px;
  margin-bottom: 3px;
}
.subheading[data-v-585d739d] {
  font-weight: 300;
  font-size: 14px;
  color: #c4c4c4;
}
.required-star[data-v-585d739d] {
  color: #ff4141;
  position: absolute;
  right: 40px;
  top: 20px;

  font-size: 18px;
}
.editing-indicator[data-v-585d739d] {
  position: absolute;
  left: 0px;
  top: 0px;
  width: 10px;
  height: 100%;
  border-radius: 10px 0px 0px 10px;
  background-color: #378dcc;
  transition: 200ms;
}

/*Question Modifier Buttons*/
#Qmod-icons[data-v-585d739d] {
  opacity: 0%;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  height: 100%;

  -webkit-transform: translate(0px, -5px);

          transform: translate(0px, -5px);

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
#Qmod-icons[data-v-585d739d]:hover {
  opacity: 100%;
  transition: 200ms;
}
#block-container:hover ~ #Qmod-icons[data-v-585d739d] {
  opacity: 100%;
  transition: 200ms;
}
.btt-delQ[data-v-585d739d] {
  width: 25px;
  height: 25px;

  margin-top: 10px;
  margin-right: 20px;

  fill: #cecece;
  cursor: pointer;
}
.btt-delQ[data-v-585d739d]:hover {
  fill: #ff7070;
  transition: 200ms;
}
.btt-moveQ[data-v-585d739d] {
  width: 30px;
  height: 30px;

  margin-right: 20px;

  fill: #cecece;
  cursor: pointer;
}
.btt-moveQ[data-v-585d739d]:hover {
  fill: #378dcc;
  transition: 200ms;
}
.locked-icon-container[data-v-585d739d] {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  height: 100%;

  -webkit-transform: translate(0px, -5px);

          transform: translate(0px, -5px);

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.locked-icon[data-v-585d739d] {
  width: 30px;
  height: 30px;

  margin-right: 20px;

  fill: #cecece;
}

/*--------RADIO--------*/
.radio-single-container[data-v-585d739d] {
  display: flex;
  flex-direction: row;
  align-items: center;

  margin: 5px;
}
.radio-option-label[data-v-585d739d] {
  margin-left: 10px;
}
.radio-options-container[data-v-585d739d] {
  margin-top: 15px;
}

/*--------MULTIPLE CHOICE--------*/
.mc-single-container[data-v-585d739d] {
  display: flex;
  flex-direction: row;
  align-items: center;

  margin: 5px;
}
.mc-option-label[data-v-585d739d] {
  margin-left: 10px;
}
.mc-options-container[data-v-585d739d] {
  margin-top: 15px;
}

/*--------CONTENT--------*/
.content-item-container[data-v-585d739d] {
  margin-top: 20px;
}
.content-image[data-v-585d739d] {
  width: 100%;
  border-radius: 10px;
  margin-top: 5px;
}
.content-heading[data-v-585d739d] {
  font-weight: 500;
  color: #bcbcbc;
  margin-bottom: 5px;
}

/*--------SHORT TEXT--------*/
.shorttext-input[data-v-585d739d] {
  width: 100%;
  height: 30px;

  margin-top: 10px;
  padding-left: 10px;
  padding-right: 10px;

  font-size: 16px;

  border-color: rgba(0, 0, 0, 0);
  border-radius: 10px;
  background-color: #f7f7f7;
}
.shorttext-input[data-v-585d739d]::-webkit-input-placeholder {
  font-size: 16px;
  color: #c0c0c0;
}
.shorttext-input[data-v-585d739d]::placeholder {
  font-size: 16px;
  color: #c0c0c0;
}
.shorttext-input[data-v-585d739d]:focus {
  outline: none !important;
  border-color: rgba(0, 0, 0, 0);
  background-color: #f7f7f7;
}
.shorttext-input[data-v-585d739d]:hover {
  background-color: #eeeeee;
}

/*--------LONG TEXT--------*/
.longtext-input[data-v-585d739d] {
  width: 100%;
  min-height: 100px;

  margin-top: 10px;
  padding: 10px;
  padding-top: 5px;

  font-size: 16px;
  font-family: "Inter", sans-serif;

  border-color: rgba(0, 0, 0, 0);
  border-radius: 10px;
  background-color: #f7f7f7;

  resize: vertical;
  overflow-y: auto;
}
.longtext-input[data-v-585d739d]::-webkit-input-placeholder {
  font-size: 16px;
  color: #c0c0c0;
}
.longtext-input[data-v-585d739d]::placeholder {
  font-size: 16px;
  color: #c0c0c0;
}
.longtext-input[data-v-585d739d]:focus {
  outline: none !important;
  border-color: rgba(0, 0, 0, 0);
  background-color: #f7f7f7;
}
.longtext-input[data-v-585d739d]:hover {
  background-color: #eeeeee;
}
.longtext-input[data-v-585d739d]:hover::-webkit-scrollbar-track {
  background: rgba(241, 241, 241, 1);
  opacity: 100%;
  -webkit-transition: 200ms;
  transition: 200ms;
}
.longtext-input[data-v-585d739d]:hover::-webkit-scrollbar-thumb {
  background: rgba(220, 220, 220, 1);
  opacity: 100%;
  -webkit-transition: 200ms;
  transition: 200ms;
}
.maxchar-count[data-v-585d739d] {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  margin-top: 5px;
  margin-bottom: -8px;

  font-size: 12px;
  font-weight: 600;
  color: #bcbcbc;
}

/*--------DROPDOWN--------*/
.dropdown-bar[data-v-585d739d] {
  width: 60%;
  height: 30px;
  position: relative;
  display: flex;
  align-items: center;

  margin-top: 20px;
  padding-left: 15px;
  padding-right: 30px;

  font-size: 16px;

  border-radius: 10px;
  background-color: #f7f7f7;
  cursor: pointer;
}
.dropdown-bar[data-v-585d739d]:hover {
  background-color: #eeeeee;
}
.dropdown-triangle[data-v-585d739d] {
  position: absolute;
  right: 10px;
  top: 10px;
  fill: #bcbcbc;
}
.dropdown-field[data-v-585d739d] {
  width: 100%;
  max-height: 200px;
  position: absolute;
  top: 0px;
  left: 0px;
  z-index: 10;
  overflow-y: auto;

  padding-top: 10px;
  padding-bottom: 10px;
  font-size: 14px;

  border-color: rgba(46, 18, 18, 0);
  border-radius: 10px;
  background-color: #f7f7f7;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
}
.dropdown-field[data-v-585d739d]:hover::-webkit-scrollbar-track {
  background: rgba(241, 241, 241, 1);
  opacity: 100%;
  -webkit-transition: 200ms;
  transition: 200ms;
}
.dropdown-field[data-v-585d739d]:hover::-webkit-scrollbar-thumb {
  background: rgba(220, 220, 220, 1);
  opacity: 100%;
  -webkit-transition: 200ms;
  transition: 200ms;
}
.dropdown-defocuser[data-v-585d739d] {
  width: 100vw;
  height: 100vh;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 2;
}
.dropdown-option-container[data-v-585d739d] {
  width: 100%;
  min-height: 28px;
  padding: 5px;
  padding-left: 25px;
  padding-right: 25px;
  overflow-x: hidden;
  cursor: pointer;

  -webkit-user-select: none; /* Safari */ /* Firefox */ /* IE10+/Edge */
  user-select: none; /* Standard */
}
.dropdown-option-container[data-v-585d739d]:hover {
  background-color: #eeeeee;
}
.dropdown-selection-text[data-v-585d739d] {
  white-space: nowrap;
  font-size: 16px;
}

/*--------SELECT MULTIPLE--------*/
.selmult-searchbar[data-v-585d739d] {
  position: relative;
  width: 60%;
}
.selmult-subbar[data-v-585d739d] {
  width: 100%;
  height: 30px;
  position: relative;
  z-index: 2;
  display: flex;
  align-items: center;

  margin-top: 20px;
  padding-left: 15px;
  padding-right: 30px;

  font-size: 16px;

  outline: none !important;
  border-color: rgba(0, 0, 0, 0);
  border-radius: 10px;
  background-color: #f7f7f7;
}
.selmult-subbar[data-v-585d739d]::-webkit-input-placeholder {
  font-size: 16px;
  color: #c0c0c0;
}
.selmult-subbar[data-v-585d739d]::placeholder {
  font-size: 16px;
  color: #c0c0c0;
}
.selmult-subbar[data-v-585d739d]:focus {
  outline: none !important;
  border-color: rgba(0, 0, 0, 0);
  background-color: #eeeeee;
}
.selmult-subbar[data-v-585d739d]:hover {
  background-color: #eeeeee;
}
.selmult-icon[data-v-585d739d] {
  position: absolute;
  z-index: 2;
  right: 10px;
  top: 8px;
  fill: #bcbcbc;
}
.selmult-drop-field[data-v-585d739d] {
  width: 100%;
  max-height: 200px;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  overflow-y: hidden;

  padding-top: 35px;
  padding-bottom: 10px;
  font-size: 14px;

  border-color: rgba(46, 18, 18, 0);
  border-radius: 10px 10px 5px 5px;
  background-color: #f7f7f7;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
}
.selmult-subdropfield[data-v-585d739d] {
  width: 100%;
  max-height: 165px;
  overflow-y: auto;
}
.selmult-subdropfield[data-v-585d739d]:hover::-webkit-scrollbar-track {
  background: rgba(241, 241, 241, 1);
  opacity: 100%;
  -webkit-transition: 200ms;
  transition: 200ms;
}
.selmult-subdropfield[data-v-585d739d]:hover::-webkit-scrollbar-thumb {
  background: rgba(220, 220, 220, 1);
  opacity: 100%;
  -webkit-transition: 200ms;
  transition: 200ms;
}
.selmult-selections-container[data-v-585d739d] {
  margin-top: 10px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
.selmult-selection[data-v-585d739d] {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  padding: 3px;
  padding-left: 10px;
  padding-right: 10px;
  margin-right: 5px;
  margin-bottom: 3px;

  display: flex;
  justify-content: center;
  align-items: center;

  border-radius: 5px;
  background-color: #bae4fa;
  color: #00a3ff;

  font-size: 10px;
  cursor: pointer;

  -webkit-user-select: none; /* Safari */ /* Firefox */ /* IE10+/Edge */
  user-select: none; /* Standard */
}
.selmult-selection[data-v-585d739d]:hover {
  background-color: #7eb6df;
  color: #ffffff;
  transition: 200ms;
}
.selmult-max-warning[data-v-585d739d] {
  width: 200px;

  position: absolute;
  bottom: 20px;
  right: 30px;

  color: #ff7070;
  font-size: 12px;
  transition: 200ms;
}

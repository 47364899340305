/*--------GENERAL FORMATTING-------*/
.fade[data-v-38effd52] {
  opacity: 0%;
  transition: 200ms;
}
.centering-container[data-v-38effd52] {
  width: 100%;
  min-height: 90vh;
  display: flex;
  align-items: center;
  justify-content: center;
}
.register-container[data-v-38effd52] {
  width: 60%;
  display: flex;
  flex-direction: column;
  margin-top: 50px;
  margin-bottom: 70px;
}
.form-header[data-v-38effd52] {
  font-size: 32px;
  font-weight: 700;
  color: #4b6980;
  margin-bottom: 20px;
}
.form[data-v-38effd52] {
  width: 100%;
  display: flex;
  flex-direction: row;
}
.form-left[data-v-38effd52] {
  display: flex;
  flex-direction: column;
}
.form-right[data-v-38effd52] {
  display: flex;
  flex-direction: column;
  margin-left: 50px;
}
.input-label[data-v-38effd52] {
  font-size: 14px;
  font-weight: 600;
  color: #707070;
  margin-top: 15px;
}
.input-field[data-v-38effd52] {
  width: 300px;
  height: 30px;

  margin-top: 5px;
  padding-left: 10px;
  padding-right: 10px;

  font-size: 14px;

  border-color: rgba(0, 0, 0, 0);
  border-radius: 5px;
  background-color: #ffffff;
}
.input-field[data-v-38effd52]::-webkit-input-placeholder {
  font-size: 16px;
  color: #c0c0c0;
}
.input-field[data-v-38effd52]::placeholder {
  font-size: 16px;
  color: #c0c0c0;
}
.input-field[data-v-38effd52]:focus {
  outline: none !important;
  border-color: rgba(0, 0, 0, 0);
  background-color: #ffffff;
}
.input-field[data-v-38effd52]:hover {
  background-color: #f7f7f7;
}
.additional-text[data-v-38effd52] {
  display: flex;
  align-items: row;

  font-size: 14px;
  font-weight: 600;
  color: #c4c4c4;
}
.additional-link[data-v-38effd52] {
  font-size: 14px;
  font-weight: 600;
  text-decoration: none;
  color: #3c8cc9;
}
.btt-container[data-v-38effd52] {
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
}
.warning[data-v-38effd52] {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  position: absolute;
  left: 170px;

  font-size: 14px;
  font-weight: 500;
  color: #ff7070;
  transition: 200ms;
}
.btt-register[data-v-38effd52] {
  width: 150px;
  display: flex;
  align-items: center;
  justify-content: center;

  font-size: 14px;
  font-weight: 700;

  padding: 7px;
  padding-left: 15px;
  padding-right: 15px;

  background-color: #4b6980;
  color: #ffffff;
  border-radius: 5px;
  cursor: pointer;
}
.btt-register[data-v-38effd52]:hover {
  background-color: #6c92af;
  color: #ffffff;
  transition: 200ms;
}

/*----- EULA Stuff -----*/
.eula-container[data-v-38effd52] {
  display: grid;
  grid-template-rows: 1fr 1fr;
  grid-gap: 8px;
  gap: 8px;
}
.eula-link[data-v-38effd52] {
  font-weight: 500;
  color: #378dcc;
}
.eula-check[data-v-38effd52] {
  width: 25px;
  height: 25px;
  cursor: pointer;
  background-color: #ffffff;
}

.delbar-container[data-v-b6bd4269] {
  width: 100%;
  height: 45px;
  min-width: 250px;
  min-height: 45px;

  margin-bottom: 10px;

  display: flex;
  flex-direction: row;
  align-items: center;
}
.delbar[data-v-b6bd4269] {
  flex-grow: 1;
  height: 100%;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  background-color: #ffffff;
  border-radius: 10px;

  transition: 200ms;
  cursor: pointer;
}
.delbar[data-v-b6bd4269]:hover {
  background-color: #f7f7f7;
  transition: 200ms;
}
.delbar-selected[data-v-b6bd4269] {
  flex-grow: 1;
  height: 100%;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  background-color: #ffffff;
  border-radius: 10px;

  background-color: #bae4fa;
  transition: 200ms;
  cursor: pointer;
}
.delbar-selected[data-v-b6bd4269]:hover {
  background-color: #7fb5db;
  transition: 200ms;
}
.del-name[data-v-b6bd4269] {
  font-weight: 600;
  margin-left: 30px;
}
.codebox[data-v-b6bd4269] {
  width: 80px;
  height: 100%;

  margin-left: 10px;
  margin-right: 5px;

  border-radius: 10px;
  background-color: #eeeeee;

  display: flex;
  align-items: center;
  justify-content: center;

  font-weight: 400;

  transition: 200ms;
  cursor: pointer;
}
.codebox-selected[data-v-b6bd4269] {
  background-color: #bae4fa;
  opacity: 80%;
}
.codebox[data-v-b6bd4269]:hover {
  background-color: #cccccc;
  transition: 200ms;
}
.icon[data-v-b6bd4269] {
  fill: #378dcc;
  margin-right: 5px;
}
.fade[data-v-b6bd4269] {
  opacity: 0%;
  transition: 200ms;
}

/*-------OPERATIONS-------*/
.card-operations[data-v-1fdc53de] {
  width: 100%;

  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.btt-1[data-v-1fdc53de] {
  width: 40px;
  height: 40px;

  margin: 5px;

  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 20%;
  cursor: pointer;
}
.card-btt[data-v-1fdc53de]:hover {
  transition: 200ms;
}
.btts-right[data-v-1fdc53de] {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}
.btt-pass[data-v-1fdc53de] {
  color: #3cdc27;
}
.btt-pass[data-v-1fdc53de]:hover {
  background-color: #cff9c3;
}
.btt-table[data-v-1fdc53de] {
  color: #ffca39;
}
.btt-table[data-v-1fdc53de]:hover {
  background-color: #ffe49a;
}
.btt-fail[data-v-1fdc53de] {
  color: #ff7070;
}
.btt-fail[data-v-1fdc53de]:hover {
  background-color: #ffc6c6;
}
.btt-present[data-v-1fdc53de] {
  color: #b1b1b1;
}
.btt-present[data-v-1fdc53de]:hover {
  color: #3c8cc9;
  background-color: #bae4fa;
}

/*------CONFIRMATION------*/
.confirm-operations[data-v-1fdc53de] {
  display: flex;
  flex-direction: column;
}
.btt-2[data-v-1fdc53de] {
  height: 30px;
  margin-bottom: 10px;
  padding: 5px;
  padding-left: 10px;
  padding-right: 10px;

  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  cursor: pointer;

  -webkit-user-select: none; /* Safari */ /* Firefox */ /* IE10+/Edge */
  user-select: none; /* Standard */
}
.btt-2-text[data-v-1fdc53de] {
  font-size: 12px;
  font-weight: 500;
  margin-right: 5px;
}
.confirm-container[data-v-1fdc53de] {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
}
.confirm-cancel[data-v-1fdc53de] {
  margin-left: 5px;
  background-color: #eeeeee;
  color: #b1b1b1;
}
.confirm-cancel[data-v-1fdc53de]:hover {
  background-color: #b1b1b1;
  color: white;
}
.confirm-pass[data-v-1fdc53de] {
  background-color: #cff9c3;
  color: #3cdc27;
}
.confirm-pass[data-v-1fdc53de]:hover {
  background-color: #3cdc27;
  color: white;
}
.confirm-fail[data-v-1fdc53de] {
  background-color: #ffc6c6;
  color: #ff7070;
}
.confirm-fail[data-v-1fdc53de]:hover {
  background-color: #ff7070;
  color: white;
}
.feedback-field[data-v-1fdc53de] {
  width: 100%;
  height: 100px;
  resize: none;

  display: flex;
  align-items: center;

  padding: 5px;
  padding-left: 10px;
  padding-right: 10px;
  margin-bottom: 20px;

  font-size: 14px;
  font-family: "Inter", sans-serif;

  border-color: rgba(0, 0, 0, 0);
  border-radius: 10px;
  background-color: #ffffff;
}
.feedback-field[data-v-1fdc53de]::-webkit-input-placeholder {
  font-size: 14px;
  color: #c0c0c0;
}
.feedback-field[data-v-1fdc53de]::placeholder {
  font-size: 14px;
  color: #c0c0c0;
}
.feedback-field[data-v-1fdc53de]:focus {
  outline: none !important;
  border-color: rgba(0, 0, 0, 0);
}
.feedback-field[data-v-1fdc53de]:hover {
  background-color: #eeeeee;
}

.history-container[data-v-41409a28] {
  margin: auto;

  width: 75%;
  height: 80%;
  max-width: 1600px;

  display: flex;
  flex-direction: row;
}
.nowrap[data-v-41409a28] {
  white-space: nowrap;
}
.hidden[data-v-41409a28] {
  display: none;
}
.super-z[data-v-41409a28] {
  z-index: 10;
}

/*--------UI DOM--------*/
.UI-right[data-v-41409a28] {
  height: 100%;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.UI-left[data-v-41409a28] {
  width: 1px;
  height: 100%;

  display: flex;
  flex-direction: column;
  flex-grow: 1;
  justify-content: space-between;

  margin-right: 30px;
}
.UI-topright[data-v-41409a28] {
  display: flex;
  justify-content: space-between;
  align-items: center;

  margin-bottom: 20px;
  margin-right: 7px;
}

/*--------NO CARDS--------*/
.no-selection-card[data-v-41409a28] {
  min-width: 350px;
  max-width: 350px;
  height: 100%;

  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;

  font-size: 18px;
  font-weight: 600;
  color: #bcbcbc;

  background-color: #f3f3f3;
  border-radius: 10px;
}
.no-cards-box[data-v-41409a28] {
  width: 100%;
  height: 100%;

  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;

  font-size: 18px;
  font-weight: 600;
  color: #bcbcbc;

  background-color: #f3f3f3;
  border-radius: 10px;
}

/*--------RIGHT-UI--------*/
.cardbar-container[data-v-41409a28] {
  width: 100%;

  display: flex;
  flex-direction: row;
  margin-bottom: 3px;

  border-radius: 3px;
  background-color: #ffffff;
  cursor: pointer;
  overflow: hidden;
}
.cardbar-body[data-v-41409a28] {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  flex-grow: 1;
  width: 1px;

  padding: 5px;
  padding-left: 20px;
  padding-right: 20px;
}
.deck-container[data-v-41409a28] {
  width: calc(100% + 5px);
  padding-right: 7px;
  overflow-y: auto;
  flex-grow: 1;
}
.deck-container[data-v-41409a28]:hover::-webkit-scrollbar-track {
  background: rgba(241, 241, 241, 1);
  opacity: 100%;
  -webkit-transition: 200ms;
  transition: 200ms;
}
.deck-container[data-v-41409a28]:hover::-webkit-scrollbar-thumb {
  background: rgba(220, 220, 220, 1);
  opacity: 100%;
  -webkit-transition: 200ms;
  transition: 200ms;
}
.cardbar-indicator[data-v-41409a28] {
  min-width: 15px;
  min-height: 15px;
}
.selected[data-v-41409a28] {
  background-color: #bae4fa;
  transition: 200ms;
}
.card-container[data-v-41409a28] {
  height: calc(100% - 50px);
}
.cardbar-left[data-v-41409a28] {
  width: 70%;
  flex-direction: row;
  align-items: center;
}
.cardbar-right[data-v-41409a28] {
  width: 30%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
.cardbar-title[data-v-41409a28] {
  margin-right: 15px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 14px;
}
.cardbar-desc[data-v-41409a28] {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: #aaaaaa;
  font-size: 12px;
}
.cardbar-id[data-v-41409a28] {
  margin-left: 15px;
  font-size: 10px;
  font-weight: 600;
  color: #aaaaaa;
}

/*History Operations*/
.history-operations[data-v-41409a28] {
  width: 100%;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;

  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.btt-clear-history[data-v-41409a28] {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;

  display: flex;
  align-items: center;
  justify-content: center;

  font-size: 14px;
  font-weight: 600;

  padding: 8px;
  padding-left: 25px;
  padding-right: 25px;

  background-color: #f3b9c9;
  color: #ff4343;
  border-radius: 10px;
  cursor: pointer;

  -webkit-user-select: none; /* Safari */ /* Firefox */ /* IE10+/Edge */
  user-select: none; /* Standard */
}
.btt-clear-history[data-v-41409a28]:hover {
  background-color: #ff7979;
  color: #ffffff;
  transition: 200ms;
}
.btt-export-history[data-v-41409a28] {
  width: 190px;

  display: flex;
  align-items: center;
  justify-content: space-between;

  font-size: 14px;
  font-weight: 600;

  padding: 8px;
  padding-left: 25px;
  padding-right: 25px;

  background-color: #eeeeee;
  border-radius: 10px;
  cursor: pointer;

  -webkit-user-select: none; /* Safari */ /* Firefox */ /* IE10+/Edge */
  user-select: none; /* Standard */
}
.btt-export-history[data-v-41409a28]:hover {
  background-color: #d9d9d9;
  transition: 200ms;
}

/*--------FILTER DROPDOWN--------*/
.filter-group[data-v-41409a28] {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.filter-icon[data-v-41409a28] {
  fill: #bcbcbc;
  margin-right: 10px;
}
.dropdown-bar[data-v-41409a28] {
  width: 200px;
  height: 30px;
  position: relative;
  display: flex;
  align-items: center;

  padding-left: 15px;
  padding-right: 30px;

  font-size: 16px;

  border-radius: 10px;
  background-color: #ffffff;
  cursor: pointer;
}
.dropdown-bar[data-v-41409a28]:hover {
  background-color: #eeeeee;
}
.dropdown-triangle[data-v-41409a28] {
  position: absolute;
  right: 10px;
  top: 10px;
  fill: #bcbcbc;
}
.dropdown-field[data-v-41409a28] {
  width: 100%;
  max-height: 200px;
  position: absolute;
  top: 0px;
  left: 0px;
  z-index: 10;
  overflow-y: auto;

  padding-top: 10px;
  padding-bottom: 10px;
  font-size: 14px;

  border-color: rgba(46, 18, 18, 0);
  border-radius: 10px;
  background-color: #f7f7f7;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
}
.dropdown-field[data-v-41409a28]:hover::-webkit-scrollbar-track {
  background: rgba(241, 241, 241, 1);
  opacity: 100%;
  -webkit-transition: 200ms;
  transition: 200ms;
}
.dropdown-field[data-v-41409a28]:hover::-webkit-scrollbar-thumb {
  background: rgba(220, 220, 220, 1);
  opacity: 100%;
  -webkit-transition: 200ms;
  transition: 200ms;
}
.dropdown-defocuser[data-v-41409a28] {
  width: 100vw;
  height: 100vh;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 2;
}
.dropdown-option-container[data-v-41409a28] {
  width: 100%;
  height: 28px;
  padding: 5px;
  padding-left: 25px;
  padding-right: 25px;
  overflow-x: hidden;
  cursor: pointer;

  -webkit-user-select: none; /* Safari */ /* Firefox */ /* IE10+/Edge */
  user-select: none; /* Standard */
}
.dropdown-option-container[data-v-41409a28]:hover {
  background-color: #eeeeee;
}
.dropdown-text-container[data-v-41409a28] {
  overflow: hidden;
  font-size: 14px;
}

/*--------SEARCHBAR---------*/
.searchbar[data-v-41409a28] {
  position: relative;
  width: 300px;
}
.subbar[data-v-41409a28] {
  width: 100%;
  height: 30px;
  position: relative;
  z-index: 2;
  display: flex;
  align-items: center;

  padding-left: 15px;
  padding-right: 30px;

  font-size: 16px;

  outline: none !important;
  border-color: rgba(0, 0, 0, 0);
  border-radius: 10px;
  background-color: #ffffff;
}
.subbar[data-v-41409a28]::-webkit-input-placeholder {
  font-size: 16px;
  color: #c0c0c0;
}
.subbar[data-v-41409a28]::placeholder {
  font-size: 16px;
  color: #c0c0c0;
}
.subbar[data-v-41409a28]:focus {
  outline: none !important;
  border-color: rgba(0, 0, 0, 0);
  background-color: #eeeeee;
}
.subbar[data-v-41409a28]:hover {
  background-color: #eeeeee;
}
.search-icon[data-v-41409a28] {
  position: absolute;
  z-index: 2;
  right: 10px;
  top: 8px;
  fill: #bcbcbc;
}

/*----- Bricked Button -----*/
.btt-bricked[data-v-41409a28] {
  width: 190px;

  display: flex;
  align-items: center;
  justify-content: space-between;

  font-size: 14px;
  font-weight: 600;

  padding: 8px;
  padding-left: 25px;
  padding-right: 25px;

  background-color: #f3f3f3;
  color: #bcbcbc;
  border-radius: 10px;
  cursor: pointer;

  -webkit-user-select: none; /* Safari */ /* Firefox */ /* IE10+/Edge */
  user-select: none; /* Standard */
}

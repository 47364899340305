.navbar-container[data-v-0df8b7a0] {
  width: 100%;
  display: flex;
  justify-content: center;
  position: relative;
  z-index: 10;
}
.navbar[data-v-0df8b7a0] {
  width: 60%;
  height: 80px;
  margin: auto;
  margin-top: 10px;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  -webkit-user-select: none; /* Safari */ /* Firefox */ /* IE10+/Edge */
  user-select: none; /* Standard */
}
.logo[data-v-0df8b7a0] {
  width: 40px;
}
.options[data-v-0df8b7a0] {
  width: 430px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
.option-text[data-v-0df8b7a0] {
  font-size: 14px;
  font-weight: 600;
  text-decoration: none;
  color: black;
  cursor: pointer;
}
.option-text[data-v-0df8b7a0]:hover {
  color: #bcbcbc;
  transition: 200ms;
}
.btt-primary[data-v-0df8b7a0] {
  font-size: 14px;
  font-weight: 600;

  padding: 8px;
  padding-left: 25px;
  padding-right: 25px;
  margin-left: -10px;

  background-color: #bae4fa;
  text-decoration: none;
  color: #00a3ff;
  border-radius: 10px;
  cursor: pointer;
}
.btt-primary[data-v-0df8b7a0]:hover {
  background-color: #4bb4ff;
  color: #ffffff;
  transition: 200ms;
}
.option-text-white[data-v-0df8b7a0] {
  font-size: 14px;
  font-weight: 600;
  text-decoration: none;
  color: white;
  cursor: pointer;
}
.option-text-white[data-v-0df8b7a0]:hover {
  opacity: 0.6;
  transition: 200ms;
}
.btt-primary-white[data-v-0df8b7a0] {
  font-size: 14px;
  font-weight: 600;

  padding: 8px;
  padding-left: 25px;
  padding-right: 25px;
  margin-left: -10px;

  background-color: rgba(256, 256, 256, 0.3);
  text-decoration: none;
  color: white;
  border-radius: 10px;
  cursor: pointer;
}
.btt-primary-white[data-v-0df8b7a0]:hover {
  background-color: rgba(256, 256, 256, 0.5);
  transition: 200ms;
}
.btt-signout[data-v-0df8b7a0] {
  display: flex;
  align-items: center;
  justify-content: center;

  font-size: 14px;
  font-weight: 600;

  padding: 8px;
  padding-left: 25px;
  padding-right: 25px;

  background-color: #4b6980;
  color: #ffffff;
  border-radius: 10px;
  cursor: pointer;
}
.btt-signout[data-v-0df8b7a0]:hover {
  background-color: #6c92af;
  color: #ffffff;
  transition: 200ms;
}
@media (max-width: 641px) {
.navbar[data-v-0df8b7a0] {
    margin: 0px;
    margin-top: 30px;
    height: 50px;
    width: 70%;
}
.options[data-v-0df8b7a0] {
    display: none;
}
.logo[data-v-0df8b7a0] {
    width: 30px;
}
}

.delegations-container[data-v-ef009254] {
  width: 75%;
  height: 80%;
  max-width: 1600px;

  display: flex;
  flex-direction: column;
}
.flex-row[data-v-ef009254] {
  display: flex;
  flex-direction: row;
}
.full-size[data-v-ef009254] {
  width: 100%;
  height: 100%;
}
.hide[data-v-ef009254] {
  opacity: 0%;
  visibility: hidden;
  transition: 200ms;
}
.px18[data-v-ef009254] {
  font-size: 18px;
}
.noselect[data-v-ef009254] {
  -webkit-user-select: none; /* Safari */ /* Firefox */ /* IE10+/Edge */
  user-select: none; /* Standard */
}

/*----- Welcome -----*/
.welcome[data-v-ef009254] {
  position: absolute;
  top: 30px;
  left: 50px;
  color: #bcbcbc;
}
.welcome-heading[data-v-ef009254] {
  font-size: 26px;
}
.welcome-subheading[data-v-ef009254] {
  font-size: 16px;
  font-weight: 600;
}

/*-----------LEFT UI-----------*/
.UI-left[data-v-ef009254] {
  min-width: 300px;
  width: 25%;
  height: 100%;

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
}
.UI-topleft[data-v-ef009254] {
  width: 100%;

  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.delcount-container[data-v-ef009254] {
  width: 90%;
  height: 200px;

  background-color: #ffffff;
  border-radius: 10px;
  border-style: solid;
  border-width: 1px;
  border-color: #f7f7f7;

  margin-bottom: 20px;
}
.delcount-subcont[data-v-ef009254] {
  padding: 30px;
}
.delcount-num[data-v-ef009254] {
  font-size: 72px;
  font-weight: 600;
}
.delcount-desc[data-v-ef009254] {
  font-size: 18px;
  font-weight: 700;
  color: #bcbcbc;
}
.btt-add-country[data-v-ef009254] {
  width: 90%;
  height: 35px;

  background-color: #bae4fa;
  color: #378dcc;
  font-weight: 600;
  border-radius: 10px;

  display: flex;
  justify-content: center;
  align-items: center;

  margin-top: 7px;
  cursor: pointer;
}
.btt-add-country[data-v-ef009254]:hover {
  background-color: #7eb6df;
  color: #ffffff;
  transition: 200ms;
}
.btt-select-all[data-v-ef009254] {
  width: 90%;
  height: 35px;

  background-color: #ffe49a;
  color: #daa000;
  font-weight: 600;
  border-radius: 10px;

  display: flex;
  justify-content: center;
  align-items: center;

  margin-top: 7px;

  transition: 200ms;
  cursor: pointer;
}
.btt-select-all[data-v-ef009254]:hover {
  background-color: #ffca39;
  color: #ffffff;
  transition: 200ms;
}
.btt-remove-selected[data-v-ef009254] {
  width: 90%;
  height: 35px;

  background-color: #f3b9c9;
  color: #ff4343;
  font-weight: 600;
  border-radius: 10px;

  display: flex;
  justify-content: center;
  align-items: center;

  margin-top: 7px;

  transition: 200ms;
  cursor: pointer;
}
.btt-remove-selected[data-v-ef009254]:hover {
  background-color: #ff7979;
  color: #ffffff;
  transition: 200ms;
}
.btt-bricked[data-v-ef009254] {
  width: 90%;
  height: 35px;

  background-color: #f3f3f3;
  color: #bcbcbc;
  font-weight: 600;
  border-radius: 10px;

  display: flex;
  justify-content: center;
  align-items: center;

  margin-top: 7px;

  transition: 200ms;
  cursor: pointer;
}
.btt-export-delegations[data-v-ef009254] {
  width: 90%;

  display: flex;
  justify-content: center;
  align-items: center;

  font-size: 14px;
  font-weight: 600;

  padding: 8px;
  padding-left: 25px;
  padding-right: 25px;

  background-color: #eeeeee;
  border-radius: 10px;
  cursor: pointer;
}
.btt-export-delegations[data-v-ef009254]:hover {
  background-color: #d9d9d9;
  transition: 200ms;
}
.btt-export-delegations-inner[data-v-ef009254] {
  width: 165px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

/*-----------RIGHT UI-----------*/
.UI-right[data-v-ef009254] {
  width: 75%;
  height: 100%;

  display: flex;
  flex-direction: column;
  align-items: center;

  overflow-y: auto;
}
.UI-right[data-v-ef009254]:hover::-webkit-scrollbar-track {
  background: rgba(241, 241, 241, 1);
  opacity: 100%;
  -webkit-transition: 200ms;
  transition: 200ms;
}
.UI-right[data-v-ef009254]:hover::-webkit-scrollbar-thumb {
  background: rgba(220, 220, 220, 1);
  opacity: 100%;
  -webkit-transition: 200ms;
  transition: 200ms;
}
.no-del-container[data-v-ef009254] {
  width: 100%;
  height: 100%;

  display: flex;
  justify-content: center;
  align-items: center;

  border-radius: 10px;
  background-color: #f3f3f3;
}
.no-del-message[data-v-ef009254] {
  font-weight: 600;
  font-size: 24px;
  color: #bcbcbc;
}

.container[data-v-dc622141] {
  display: flex;
  flex-direction: column;
  gap: 7px;

  padding: 7px;
}
.set[data-v-dc622141] {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;

  font-size: 12px;
  font-weight: 500;
}
.circle[data-v-dc622141] {
  min-width: 10px;
  min-height: 10px;
  border-radius: 100%;
}
.passed[data-v-dc622141] {
  background-color: #3c8cc9;
}
.failed[data-v-dc622141] {
  background-color: #bae4fa;
}

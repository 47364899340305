.card-container[data-v-b8ee5cfd] {
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;

  display: flex;
  flex-direction: column;

  border-radius: 10px;
}
.collapsable[data-v-b8ee5cfd] {
  height: 500px;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  transition: 300ms;
}
.collapsed[data-v-b8ee5cfd] {
  height: 0px;
  transition: 300ms;
}
.rounded[data-v-b8ee5cfd] {
  border-radius: 10px !important;
  overflow: hidden;
  transition: 300ms;
}

/*-------TOP CUSTOM-------*/
.custom-top[data-v-b8ee5cfd] {
  position: relative;

  padding: 15px;
  padding-left: 30px;
  padding-right: 70px;

  display: flex;
  flex-direction: row;
  align-items: center;

  border-radius: 10px 10px 0px 0px;
  background-color: #3c8cc9;
  transition: 200ms;
}
.custom-id-tag[data-v-b8ee5cfd] {
  position: absolute;
  top: 35px;
  right: 20px;
  color: #ffffff;
  font-size: 10px;
}
.author[data-v-b8ee5cfd] {
  color: #ffffff;
  font-size: 18px;
  font-weight: 500;
}
.custom-icon[data-v-b8ee5cfd] {
  fill: #ffffff;
  min-width: 30px;
  margin-right: 10px;
}

/*-------TOP STANDARD-------*/
.standard-top[data-v-b8ee5cfd] {
  position: relative;
  padding: 15px;
  padding-left: 30px;
  padding-right: 30px;

  border-radius: 10px 10px 0px 0px;
  background-color: #3c8cc9;
  transition: 200ms;
}
.card-top-bottom[data-v-b8ee5cfd] {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: space-between;
}
.type[data-v-b8ee5cfd] {
  color: #ffffff;
  font-size: 14px;
}
.id-tag[data-v-b8ee5cfd] {
  color: #ffffff;
  font-size: 10px;
}
.title[data-v-b8ee5cfd] {
  color: #ffffff;
  font-size: 16px;
  font-weight: 500;

  margin-right: 53px;
  margin-bottom: 10px;
}

/*-------TIE-------*/
.card-tie[data-v-b8ee5cfd] {
  padding-left: 30px;
  padding-right: 30px;
  padding-top: 10px;
  padding-bottom: 5px;

  font-size: 12px;
  background-color: #f7f7f7;
}
.tie-set[data-v-b8ee5cfd] {
  display: flex;
  flex-direction: row;

  margin-bottom: 5px;
}
.tie-icon[data-v-b8ee5cfd] {
  fill: #b1b1b1;
  min-width: 18px;
  margin-right: 10px;
}
.signatories-list[data-v-b8ee5cfd] {
  color: #b1b1b1;
}

/*-------BODY-------*/
.card-body[data-v-b8ee5cfd] {
  flex-grow: 1;

  padding-left: 30px;
  padding-right: 30px;
  padding-top: 20px;
  padding-bottom: 20px;

  border-radius: 0 0 10px 10px;
  background-color: #ffffff;
  overflow-y: auto;
}
.card-body[data-v-b8ee5cfd]:hover::-webkit-scrollbar-track {
  background: rgba(241, 241, 241, 1);
  opacity: 100%;
  -webkit-transition: 200ms;
  transition: 200ms;
}
.card-body[data-v-b8ee5cfd]:hover::-webkit-scrollbar-thumb {
  background: rgba(220, 220, 220, 1);
  opacity: 100%;
  -webkit-transition: 200ms;
  transition: 200ms;
}
.block-heading[data-v-b8ee5cfd] {
  font-size: 14px;
  font-weight: 500;
  word-wrap: break-word;

  margin-bottom: 3px;
}
.block-text[data-v-b8ee5cfd] {
  color: #222222;
  font-size: 14px;
  word-wrap: break-word;
  margin-bottom: 20px;
  white-space: pre-line;
}

/*-------BODY, DELEGATE-------*/
.delcard-body[data-v-b8ee5cfd] {
  height: 500px;

  padding-left: 30px;
  padding-right: 30px;
  padding-top: 20px;
  padding-bottom: 20px;

  border-radius: 0 0 10px 10px;
  background-color: #ffffff;
  overflow-y: auto;
  transition: 300ms;
}
.delcard-body[data-v-b8ee5cfd]:hover::-webkit-scrollbar-track {
  background: rgba(241, 241, 241, 1);
  opacity: 100%;
  -webkit-transition: 200ms;
  transition: 200ms;
}
.delcard-body[data-v-b8ee5cfd]:hover::-webkit-scrollbar-thumb {
  background: rgba(220, 220, 220, 1);
  opacity: 100%;
  -webkit-transition: 200ms;
  transition: 200ms;
}
.delcard-body-collapsed[data-v-b8ee5cfd] {
  padding-left: 30px;
  padding-right: 30px;

  border-radius: 0 0 10px 10px;
  background-color: #ffffff;
  overflow: hidden;

  height: 0px;
  transition: 300ms;
}

/*-------HISTORY REVERT-------*/
.btts-right[data-v-b8ee5cfd] {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}
.btt-revert[data-v-b8ee5cfd] {
  margin-bottom: 10px;
  padding: 5px;
  padding-left: 10px;
  padding-right: 10px;

  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  cursor: pointer;
  color: #daa000;
  background-color: #ffe49a;

  -webkit-user-select: none; /* Safari */ /* Firefox */ /* IE10+/Edge */
  user-select: none; /* Standard */
}
.btt-revert[data-v-b8ee5cfd]:hover {
  color: #ffffff;
  background-color: #ffca39;
  transition: 200ms;
}
.btt-revert-text[data-v-b8ee5cfd] {
  font-size: 12px;
  font-weight: 500;
  margin-right: 5px;
}

#root {
  width: 100%;
  height: 100%;

  overflow: hidden;
}

html,
body {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
}

body {
  font-weight: 300;
  font-family: "Inter", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  box-sizing: border-box;
}
body::-webkit-scrollbar {
  display: none;
}

* {
  margin: 0;
  padding: 0;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/* width */
::-webkit-scrollbar {
  width: 7px;
}

/* Track */
::-webkit-scrollbar-track {
  background: rgba(241, 241, 241, 0);
  border-radius: 5px;
  -webkit-transition: 200ms;
  transition: 200ms;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: rgba(188, 188, 188, 0);
  border-radius: 5px;
  -webkit-transition: 200ms;
  transition: 200ms;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: rgb(160, 160, 160);
  -webkit-transition: 200ms;
  transition: 200ms;
}

* {
  box-sizing: border-box;
}

::selection {
  color: #378dcc;
  background: #bae4fa;
}

input[type="radio"] {
  /* remove standard background appearance */
  -webkit-appearance: none;
  appearance: none;
  /* create custom radiobutton appearance */
  display: inline-block;
  width: 16px;
  height: 16px;
  padding: 2px;
  /* background-color only for content */
  background-clip: content-box;
  border: 0.5px solid #bbbbbb;
  border-radius: 50%;
}

/* appearance for checked radiobutton */
input[type="radio"]:checked {
  background-color: #3c8cc9;
}

input[type="checkbox"] {
  /* remove standard background appearance */
  -webkit-appearance: none;
  appearance: none;
  /* create custom radiobutton appearance */
  display: inline-block;
  width: 16px;
  height: 16px;
  padding: 2px;
  /* background-color only for content */
  background-clip: content-box;
  border: 0.5px solid #bbbbbb;
  border-radius: 30%;
}

/* appearance for checked radiobutton */
input[type="checkbox"]:checked {
  background-color: #3c8cc9;
}

.loginpage-container[data-v-8fa0d08b] {
  width: 100%;
  height: 100%;

  display: flex;
  align-items: center;
  justify-content: center;

  background-color: #f7f7f7;
}
.UI-container[data-v-8fa0d08b] {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  min-width: 450px;
  max-width: 600px;
  display: flex;
  flex-direction: column;
}

/*----------UI TOP----------*/
.UI-top[data-v-8fa0d08b] {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
}
.UI-top-right[data-v-8fa0d08b] {
  display: flex;
  flex-direction: column;
}
.icon[data-v-8fa0d08b] {
  fill: #378dcc;
  margin-right: 20px;
}
.heading[data-v-8fa0d08b] {
  font-size: 42px;
  font-weight: 700;
  color: #bcbcbc;
}
.subheading[data-v-8fa0d08b] {
  font-size: 20px;
  font-weight: 700;
  color: #bcbcbc;
}

/*--------UI BOTTOM--------*/
.UI-bottom[data-v-8fa0d08b] {
  width: 100%;
  height: 35px;

  display: flex;
  flex-direction: row;
  margin-top: 30px;
}
.input-field[data-v-8fa0d08b] {
  width: 100%;
  height: 100%;

  padding-left: 10px;
  padding-right: 10px;

  font-size: 16px;

  border-color: rgba(0, 0, 0, 0);
  border-radius: 10px;
  background-color: #ffffff;
}
.input-field[data-v-8fa0d08b]::-webkit-input-placeholder {
  font-size: 16px;
  color: #c0c0c0;
}
.input-field[data-v-8fa0d08b]::placeholder {
  font-size: 16px;
  color: #c0c0c0;
}
.input-field[data-v-8fa0d08b]:focus {
  outline: none !important;
  border-color: rgba(0, 0, 0, 0);
  background-color: #ffffff;
}
.input-field[data-v-8fa0d08b]:hover {
  background-color: #eeeeee;
}
.btt-enter[data-v-8fa0d08b] {
  padding: 3px;
  padding-left: 15px;
  padding-right: 15px;
  margin-left: 15px;

  display: flex;
  justify-content: center;
  align-items: center;

  border-radius: 5px;
  background-color: #bae4fa;
  color: #00a3ff;
  transition: 200ms;

  font-size: 18px;
  font-weight: 500;
  cursor: pointer;
  -webkit-user-select: none; /* Safari */ /* Firefox */ /* IE10+/Edge */
  user-select: none; /* Standard */
}
.btt-enter[data-v-8fa0d08b]:hover {
  background-color: #7eb6df;
  color: #ffffff;
  transition: 200ms;
}
@media (max-width: 641px) {
.UI-container[data-v-8fa0d08b] {
    justify-content: center;
}
.UI-top[data-v-8fa0d08b] {
    justify-content: center;
}
.UI-bottom[data-v-8fa0d08b] {
    justify-content: center;
}
.heading[data-v-8fa0d08b] {
    font-size: 28px;
}
.subheading[data-v-8fa0d08b] {
    font-size: 18px;
}
.input-field[data-v-8fa0d08b] {
    width: 200px;
}
}

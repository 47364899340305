.invalid-container[data-v-eb80714d] {
  width: 100vw;
  height: 100vh;

  background-color: #f7f7f7;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.icon[data-v-eb80714d] {
  fill: #707070;
}
.heading[data-v-eb80714d] {
  font-size: 36px;
  font-weight: 600;
  margin-top: 10px;
  margin-bottom: 10px;
}
.subheading[data-v-eb80714d] {
  font-size: 24px;
  font-weight: 600;
  color: #707070;
}

.page-container[data-v-9b067ca0] {
  width: 100%;
  height: 100%;
  display: flex;

  padding-top: 30px;
  padding-bottom: 30px;

  justify-content: center;
  overflow-y: auto;
}
.innerpage-container[data-v-9b067ca0] {
  position: relative;
  width: 65%;
  max-width: 1600px;
  min-width: 750px;

  height: -webkit-fit-content;

  height: -moz-fit-content;

  height: fit-content;

  display: flex;
  flex-direction: column;

  margin: auto;
  padding-top: 20px;
  padding-bottom: 20px;
  padding-left: 30px;
  padding-right: 30px;

  border-radius: 10px;
  background-color: #ffffff;
}

/*----- Header -----*/
.page-header[data-v-9b067ca0] {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
}
.header-pair[data-v-9b067ca0] {
  display: flex;
  flex-direction: row;
  align-items: center;

  margin-bottom: 10px;
}
.header-text[data-v-9b067ca0] {
  font-size: 18px;
  font-weight: 600;
  color: #3c8cc9;
  margin-left: 15px;
}
.header-icon[data-v-9b067ca0] {
  fill: #3c8cc9;
}

/*----- Content Header -----*/
.plantype-heading[data-v-9b067ca0] {
  display: flex;
  flex-direction: row;
  font-size: 28px;
  font-weight: 700;
  margin-bottom: 10px;

  color: #707070;
}
.heading[data-v-9b067ca0] {
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 5px;
}
.subtext[data-v-9b067ca0] {
  font-size: 16px;
  color: #707070;

  display: flex;
  flex-direction: row;
}

/*----- Redeem -----*/
.redeem[data-v-9b067ca0] {
  max-width: 700px;
  display: flex;
  flex-direction: column;
  margin-top: 50px;
}
.redeem-content[data-v-9b067ca0] {
  display: flex;
  flex-direction: column;
}

/*Redeem Inputs*/
.inputs[data-v-9b067ca0] {
  width: 700px;
  height: 35px;
  margin-top: 15px;

  display: flex;
  flex-direction: row;
  align-items: center;
}
.input[data-v-9b067ca0] {
  width: 100%;
  height: 100%;

  padding-left: 10px;
  padding-right: 10px;

  font-size: 16px;

  border-color: rgba(0, 0, 0, 0);
  border-radius: 10px;
  background-color: #f7f7f7;
}
.input[data-v-9b067ca0]::-webkit-input-placeholder {
  font-size: 16px;
  color: #c0c0c0;
}
.input[data-v-9b067ca0]::placeholder {
  font-size: 16px;
  color: #c0c0c0;
}
.input[data-v-9b067ca0]:focus {
  outline: none !important;
  border-color: rgba(0, 0, 0, 0);
  background-color: #f7f7f7;
}
.input[data-v-9b067ca0]:hover {
  background-color: #eeeeee;
}
.btt-redeem[data-v-9b067ca0] {
  width: 120px;
  height: 100%;
  font-size: 14px;
  font-weight: 600;

  margin-left: 10px;

  display: flex;
  justify-content: center;
  align-items: center;

  background-color: #bae4fa;
  text-decoration: none;
  color: #00a3ff;
  border-radius: 10px;
  cursor: pointer;
}
.btt-redeem[data-v-9b067ca0]:hover {
  background-color: #4bb4ff;
  color: #ffffff;
  transition: 200ms;
}

/*----- Mockups -----*/
.mockup-container[data-v-9b067ca0] {
  width: 100%;
  height: 450px;
  -webkit-transform: translate(30px, 20px);
          transform: translate(30px, 20px);
  overflow: hidden;
  position: relative;
  border-radius: 10px;
}
.mockup[data-v-9b067ca0] {
  width: 500px;
  border-radius: 10px;
  box-shadow: 0px 0px 40px rgba(0, 0, 0, 0.15);
  overflow: hidden;
}

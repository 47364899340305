.App-container[data-v-14b1e33d] {
  width: 100%;
  height: 100%;
  background-color: #ffffff;

  display: flex;
  flex-direction: column;
  overflow-y: auto;
  overflow-x: hidden;

  position: relative;
}
[data-v-14b1e33d]::-webkit-scrollbar {
  display: none;
}
.UI-container[data-v-14b1e33d] {
  flex-grow: 1;
}

/*-------BLOCK FORMATTING------*/
.block-container[data-v-88c936de] {
  width: 400px;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;

  position: absolute;
  top: 0px;
  right: 0px;
  z-index: 1;

  background-color: white;
  border-radius: 10px;

  padding: 15px;
  padding-left: 30px;
  padding-right: 30px;
}
.heading[data-v-88c936de] {
  font-weight: 700;
  font-size: 18px;
  margin-bottom: 5px;
}
.subheading[data-v-88c936de] {
  font-weight: 500;
  font-size: 14px;
  color: #c4c4c4;
  margin-top: 15px;
  margin-bottom: 5px;
}
.subheading-icon-container[data-v-88c936de] {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
.hidden[data-v-88c936de] {
  display: none;
}
.fade[data-v-88c936de] {
  opacity: 0%;
  transition: 200ms;
}
.overflow-wrapper[data-v-88c936de] {
  overflow-x: auto;
  white-space: nowrap;
}
.overflow-wrapper[data-v-88c936de]::-webkit-scrollbar {
  display: none;
}

/*Textfield*/
.textfield-container[data-v-88c936de] {
  width: 100%;

  display: flex;
  align-items: center;

  padding: 5px;
  padding-left: 10px;
  padding-right: 10px;

  font-size: 14px;

  border-color: rgba(0, 0, 0, 0);
  border-radius: 10px;
  background-color: #f7f7f7;
}
.textfield-container[data-v-88c936de]::-webkit-input-placeholder {
  font-size: 14px;
  color: #c0c0c0;
}
.textfield-container[data-v-88c936de]::placeholder {
  font-size: 14px;
  color: #c0c0c0;
}
.textfield-container[data-v-88c936de]:focus {
  outline: none !important;
  border-color: rgba(0, 0, 0, 0);
  background-color: #f7f7f7;
}
.textfield-container[data-v-88c936de]:hover {
  background-color: #eeeeee;
}
.textfield-container-bricked[data-v-88c936de] {
  width: 100%;

  display: flex;
  align-items: center;

  padding: 5px;
  padding-left: 10px;
  padding-right: 10px;

  font-size: 14px;

  border-color: rgba(0, 0, 0, 0);
  border-radius: 10px;
  background-color: #eeeeee;
}

/*Toggle*/
.toggle-set[data-v-88c936de] {
  display: flex;
  flex-direction: row;
  align-items: center;

  position: absolute;
  top: 20px;
  right: 35px;
  cursor: pointer;
}
.toggle-bar[data-v-88c936de] {
  position: relative;
  width: 35px;
  height: 15px;

  border-radius: 10px;
}
.toggle-circle[data-v-88c936de] {
  position: absolute;
  left: 0px;

  width: 15px;
  height: 15px;
  border-radius: 50%;
}
.toggle-greybg[data-v-88c936de] {
  background-color: #f7f7f7;
  transition: 200ms;
}
.toggle-greybtt[data-v-88c936de] {
  background-color: #c4c4c4;
  transition: 200ms;
}
.toggle-redbg[data-v-88c936de] {
  background-color: #ffc6c6;
  transition: 200ms;
}
.toggle-redbtt[data-v-88c936de] {
  background-color: #ff7070;
  transition: 200ms;
}
.toggle-text-red[data-v-88c936de] {
  margin-right: 10px;
  font-size: 12px;
  font-weight: 500;
  color: #ff7070;
  transition: 200ms;
}
.toggle-text-grey[data-v-88c936de] {
  margin-right: 10px;
  font-size: 12px;
  font-weight: 500;
  color: #c4c4c4;
  transition: 200ms;
}

/*Add Option Button*/
.btt-add-option[data-v-88c936de] {
  width: 15%;
  padding: 3px;
  padding-left: 7px;
  padding-right: 7px;

  display: flex;
  justify-content: center;
  align-items: center;

  border-radius: 5px;
  background-color: #bae4fa;
  color: #00a3ff;
  transition: 200ms;

  font-size: 10px;
  cursor: pointer;
  -webkit-user-select: none; /* Safari */ /* Firefox */ /* IE10+/Edge */
  user-select: none; /* Standard */
}
.btt-add-option[data-v-88c936de]:hover {
  background-color: #7eb6df;
  color: #ffffff;
  transition: 200ms;
}
.btt-add-option-bricked[data-v-88c936de] {
  width: 15%;
  padding: 3px;
  padding-left: 7px;
  padding-right: 7px;

  display: flex;
  justify-content: center;
  align-items: center;

  border-radius: 5px;
  background-color: #eeeeee;
  color: #bcbcbc;
  transition: 200ms;

  font-size: 10px;
  -webkit-user-select: none; /* Safari */ /* Firefox */ /* IE10+/Edge */
  user-select: none; /* Standard */
}
.btt-add-all[data-v-88c936de] {
  width: 20%;
  padding: 3px;
  padding-left: 7px;
  padding-right: 7px;
  margin-left: 5px;

  display: flex;
  justify-content: center;
  align-items: center;

  border-radius: 5px;

  font-size: 10px;
  cursor: pointer;
  -webkit-user-select: none; /* Safari */ /* Firefox */ /* IE10+/Edge */
  user-select: none; /* Standard */
}
.btt-add-all[data-v-88c936de]:hover {
  background-color: #ffca39;
  color: #ffffff;
  transition: 200ms;
}
.btt-add-all-off[data-v-88c936de] {
  background-color: #ffe49a;
  color: #daa000;
}
.btt-add-all-on[data-v-88c936de] {
  color: #ffe49a;
  background-color: #daa000;
}

/*Option Handling*/
.option-container[data-v-88c936de] {
  width: 100%;
  padding: 2px;
  padding-left: 20px;
  padding-right: 20px;

  margin-bottom: 5px;

  display: flex;
  flex-direction: row;
  align-items: center;

  border-radius: 5px;

  cursor: pointer;
}
.option-container[data-v-88c936de]:hover {
  background-color: #ffc6c6;
  transition: 200ms;
}
.option-container-bricked[data-v-88c936de] {
  width: 100%;
  padding: 2px;
  padding-left: 20px;
  padding-right: 20px;

  margin-bottom: 5px;

  display: flex;
  flex-direction: row;
  align-items: center;

  border-radius: 5px;
}
.option-text[data-v-88c936de] {
  font-size: 14px;
  -webkit-user-select: none; /* Safari */ /* Firefox */ /* IE10+/Edge */
  user-select: none; /* Standard */
}
.option-adder[data-v-88c936de] {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 5px;
}
.option-input[data-v-88c936de] {
  display: flex;
  align-items: center;
  width: 85%;

  padding-left: 10px;
  padding-right: 10px;
  margin-right: 5px;

  font-size: 14px;

  border-color: rgba(0, 0, 0, 0);
  border-radius: 5px;
  background-color: #f7f7f7;
}
.option-input[data-v-88c936de]:focus {
  outline: none !important;
  border-color: rgba(0, 0, 0, 0);
  background-color: #eeeeee;
}
.option-input[data-v-88c936de]:hover {
  background-color: #eeeeee;
}
.option-input-bricked[data-v-88c936de] {
  display: flex;
  align-items: center;
  width: 85%;

  padding-left: 10px;
  padding-right: 10px;
  margin-right: 5px;

  font-size: 14px;

  border-color: rgba(0, 0, 0, 0);
  border-radius: 5px;
  background-color: #eeeeee;
}
.option-none-ind[data-v-88c936de] {
  font-size: 12px;
  font-weight: 500;
  padding-left: 20px;
  color: #bcbcbc;
}
.dummy-block[data-v-88c936de] {
  height: 200px;
  width: 100%;
  margin: auto;
  margin-top: 10px;

  border-radius: 10px;
  background-color: #f7f7f7;
}

/*-------HEADER------*/
.header-imgsrc-container[data-v-88c936de] {
  width: 75%;

  display: flex;
  align-items: center;

  padding: 5px;
  padding-left: 10px;
  padding-right: 10px;

  font-size: 14px;

  border-radius: 10px;
  background-color: #f7f7f7;
}
.header-imgbar-n-btt[data-v-88c936de] {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.header-btt-upload[data-v-88c936de] {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  padding: 3px;
  padding-left: 10px;
  padding-right: 10px;
  margin-right: 5px;

  display: flex;
  justify-content: center;
  align-items: center;

  border-radius: 5px;
  background-color: #bae4fa;
  color: #00a3ff;

  font-size: 12px;
  cursor: pointer;
  -webkit-user-select: none; /* Safari */ /* Firefox */ /* IE10+/Edge */
  user-select: none; /* Standard */
}
.header-btt-upload[data-v-88c936de]:hover {
  background-color: #7eb6df;
  color: #ffffff;
  transition: 200ms;
}

/*-------RADIO------*/
.options-container[data-v-88c936de] {
  width: 100%;
  max-height: 300px;
  overflow-y: auto;

  padding-top: 10px;
  padding-bottom: 10px;

  display: flex;
  flex-direction: column;

  background-color: #f7f7f7;
  border-radius: 10px;
  transition: 200ms;
}
.options-container[data-v-88c936de]:hover::-webkit-scrollbar-track {
  background: rgba(241, 241, 241, 1);
  opacity: 100%;
  -webkit-transition: 200ms;
  transition: 200ms;
}
.options-container[data-v-88c936de]::-webkit-scrollbar-thumb {
  background: rgba(220, 220, 220, 1);
  opacity: 100%;
  -webkit-transition: 200ms;
  transition: 200ms;
}
.options-container-bricked[data-v-88c936de] {
  background-color: #eeeeee;
  transition: 200ms;
}

/*-------MULTIPLE CHOICE------*/

/*-------CONTENT BLOCK------*/
.btt-add-content[data-v-88c936de] {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  padding: 3px;
  padding-left: 10px;
  padding-right: 10px;
  margin-right: 5px;
  margin-top: 15px;

  display: flex;
  justify-content: center;
  align-items: center;

  border-radius: 5px;
  background-color: #bae4fa;
  color: #00a3ff;

  font-size: 12px;
  cursor: pointer;
  -webkit-user-select: none; /* Safari */ /* Firefox */ /* IE10+/Edge */
  user-select: none; /* Standard */
}
.btt-add-content[data-v-88c936de]:hover {
  background-color: #7eb6df;
  color: #ffffff;
  transition: 200ms;
}
.content-text-input[data-v-88c936de] {
  width: 100%;
  min-height: 100px;

  padding: 10px;
  padding-top: 5px;

  font-size: 14px;
  font-family: "Inter", sans-serif;

  border-color: rgba(0, 0, 0, 0);
  border-radius: 10px;
  background-color: #f7f7f7;

  resize: vertical;
  overflow-y: auto;
}
.content-text-input[data-v-88c936de]::-webkit-input-placeholder {
  font-size: 14px;
  color: #c0c0c0;
}
.content-text-input[data-v-88c936de]::placeholder {
  font-size: 14px;
  color: #c0c0c0;
}
.content-text-input[data-v-88c936de]:focus {
  outline: none !important;
  border-color: rgba(0, 0, 0, 0);
  background-color: #f7f7f7;
}
.content-text-input[data-v-88c936de]:hover {
  background-color: #eeeeee;
}
.content-text-input[data-v-88c936de]:hover::-webkit-scrollbar-track {
  background: rgba(241, 241, 241, 1);
  opacity: 100%;
  -webkit-transition: 200ms;
  transition: 200ms;
}
.content-text-input[data-v-88c936de]:hover::-webkit-scrollbar-thumb {
  background: rgba(220, 220, 220, 1);
  opacity: 100%;
  -webkit-transition: 200ms;
  transition: 200ms;
}
.content-item-header-field[data-v-88c936de] {
  width: 100%;

  display: flex;
  align-items: center;

  padding: 5px;
  padding-left: 10px;
  padding-right: 10px;
  margin-bottom: 5px;

  font-size: 14px;

  border-color: rgba(0, 0, 0, 0);
  border-radius: 10px;
  background-color: #f7f7f7;
}
.content-item-header-field[data-v-88c936de]::-webkit-input-placeholder {
  font-size: 14px;
  color: #c0c0c0;
}
.content-item-header-field[data-v-88c936de]::placeholder {
  font-size: 14px;
  color: #c0c0c0;
}
.content-item-header-field[data-v-88c936de]:focus {
  outline: none !important;
  border-color: rgba(0, 0, 0, 0);
  background-color: #f7f7f7;
}
.content-item-header-field[data-v-88c936de]:hover {
  background-color: #eeeeee;
}
.content-item-delete-background[data-v-88c936de] {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  padding-left: 15px;
  padding-right: 15px;
  margin-top: 15px;

  display: flex;
  justify-content: center;
  align-items: center;

  border-radius: 5px;
  transition: 200ms;
  cursor: pointer;
}
.content-item-delete-background[data-v-88c936de]:hover {
  background-color: #ffc6c6;
  transition: 200ms;
}
.content-item-delete-icon[data-v-88c936de] {
  fill: #ff7070;
}

/*-------DROPDOWN------*/

/*-------SELECT MULTIPLE------*/
